import React from 'react';
import { Link } from "react-router-dom";
import MaterialTab from '../components/materialTab';

import Icon from '../elements/icons';
// import Gallerie from '../components/gallerie';

import ABild from "../css/image/ansprechpartner/tunde.png";

import Menu from '../components/menu';
import Carusel from '../components/carusel';
let CaruselBilder = require('../css/image/eigeneWohnung/carusel');
CaruselBilder = CaruselBilder();


const tabContents = [

    {
        title: "Unser Team",
        content: <div className="p-3 text-secondary ">
            <h4>Unser Team</h4>
            <ul>
                <li>Dipl. Sozialpädagoge*in</li>
                <li>(mit traumatherapeutischer Zusatzqualifikation)</li>
                <li>Sozialarbeiter*in</li>
                <li>Heilpädagoge*in</li>
                <li>Heilerziehungspfleger*in</li>
                <li>Erzieher*in</li>
                <li>Student*in für soziale Arbeit</li>
            </ul>
        </div>
    },
    {
        title: "Voraussetzungen und Kostenübernahme",
        content: <div className="p-3 text-secondary ">
            <h4>Voraussetzungen und Kostenübernahme:</h4>
            <ul>
                <li>Wohnen in eigener Wohnung oder im Familienverband</li>
                <li>geistige, seelische, körperliche Beeinträchtigung oder Beeinträchtigung der Sinne</li>
                <li>vollendetes 18. Lebensjahr</li>
                <li>Bedarfsermittlung nach Antragstellung durch den Fachdienst der Eingliederungshilfe</li>
                <li>Kostenübernahme durch den Sozialhilfeträger gemäß SGB IX</li>
            </ul>

        </div>
    }
]
class EigeneWohnung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }



    /*  createPhotos(data) {
         let photos = [];
         for (let i = 0; i < data.length; i++) {
             let item = {
                 src: data[i],
                 width: 10,
                 height: 15
             }
             photos.push(item);
         }
         return photos;
     }
  */




    render() {
        window.scrollTo(0, 0);
        // let photos = this.createPhotos(require('../css/image/schuleWaltkater/content')());
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Assistenz beim Wohnen</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>
                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Assistenz beim Wohnen</h3>
                                    {/*  <h5 className="text-success ff-3">„“</h5> */}
                                    <p className="text-secondary">
                                        Unser Angebot richtet sich an volljährige Menschen mit unterschiedlichen Bedürfnissen, die bereits in
                                        ihrer eigenen Wohnung oder im Familienverband leben.
                                        Wir orientieren uns am individuellen Bedarf eines jeden Einzelnen. Gemeinsam werden Interessen
                                        und bedürfnisorientierte Ziele entwickelt und erarbeitet, die auf die persönlichen Wünsche und
                                        Fähigkeiten abgestimmt sind.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 p-0  ">

                                <img alt="s" src={require('../css/image/eigeneWohnung')()[0]} height="auto" width="100%" />
                            </div>

                            <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>„Alltag gestalten, Zukunft realisieren“</h4>
                            </div>


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    <li>Förderung des Selbstwertes, der Selbstakzeptanz und Entfaltung der Persönlichkeit</li>
                                    <li>Einbindung in soziale Gruppe und Förderung der sozialen Kontakte</li>
                                    <li>Aktive und selbstbestimmte Gestaltung der Freizeit</li>
                                    <li>Unterstützung beim schulischen Werdegang und aufzeigen beruflicher Perspektiven</li>
                                    <li>Beratung in Konflikt- und Krisensituationen</li>
                                    <li>Befähigende Begleitung bei Behördengängen und Arztbesuchen</li>
                                    <li>Ressourcenorientierte Alltagsbewältigung und Tagesstrukturierung</li>
                                    <li>Assistenz bei Haushaltsführung, Selbstversorgung und Geldeinteilung / Finanzen</li>
                                    <p className="mt-2">
                                        Wir bieten außerdem genau abgestimmte Gruppenangebote unter der Woche, am Wochenende und an
                                        Feiertagen, wie beispielsweise gemeinsames Frühstück oder Kochen im Team. Durch regelmäßige
                                        Aktivitäten, Ausflüge und Unternehmungen ermöglichen wir die uneingeschränkte Teilhabe am Leben
                                        in der Gesellschaft.
                                    </p>
                                </p>
                            </div>

                        </div>
                    </div>
                </>

                <>
                    <div className="container shadow-sm p-0 rounded mt-3">
                        <h3 className="p-2 text-info bg-white m-0 ff-1 text-center">Assistenz beim Wohnen</h3>
                        <div className="row m-0 bg-white anfangbuchstabegross">
                            <div className="col-12 col-md-4 d-flex">
                                <div className="m-auto">
                                    <p className="m-0">Alltagsbewältigung</p>
                                    <p className="m-0">Selbstbestimmung</p>
                                    <p className="m-0">Soziale Kontakte</p>
                                    <p className="m-0">Integration</p>
                                    <p className="m-0">Struktur im Alltag</p>
                                    <p className="m-0">Teilhabe</p>
                                    <p className="m-0">Entwicklung</p>
                                    <p className="m-0">Neuanfang</p>
                                    <p className="m-0">Zusammenhalt</p>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 d-flex">
                                <div className="m-auto">
                                    <p className="m-0">Barrierefrei  </p>
                                    <p className="m-0">Eigenverantwortung </p>
                                    <p className="m-0">Interessenorientiert </p>
                                    <p className="m-0">Miteinander</p>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 d-flex">
                                <div className="m-auto">

                                    <p className="m-0">Wohlbefinden</p>
                                    <p className="m-0">Orientierungshilfe</p>
                                    <p className="m-0">Hobby &amp; Freizeitgestaltung</p>
                                    <p className="m-0">Not &amp; Krisensituation</p>
                                    <p className="m-0">Empathisch</p>
                                    <p className="m-0">Natürlich</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>



                {/*  <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                </>
                <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div> */}

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12  bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-6 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Tünde Meier</h3>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 350</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 359 </p>
                                            <p className="m-0"><Icon icon="Mail" /> t.meier@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>

            </div>
        </>);
    }
}

export default EigeneWohnung;

















