import React from 'react';
import { Link } from "react-router-dom";

import Carusel from '../components/carusel';
import Icon from '../elements/icons';

import Menu from '../components/menu';

import ABild from "../css/image/ansprechpartner/petra.png";
import Bild from '../css/image/alexandra/1.JPG';
let CaruselBilder = require('../css/image/alexandra/carusel');
CaruselBilder = CaruselBilder();


class Alexandra extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        window.scrollTo(0, 0);
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Alexandra</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>


                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Alexandra</h3>
                                    {/* <h5 className="text-success ff-3">Kein text</h5> */}
                                    <p className="text-secondary">
                                        Im Haus Alexandra befinden sich 5 Wohngruppen mit bis zu 8 Wohnplätzen auf
                                        verschiedenen Ebenen mit Treppenanlage - also ein Haus für unsere „Mobilisten“. Im
                                        Erdgeschoss stehen 2 kleine Apartments zur Verfügung.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />

                            {/*  <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>„Miteinander Füreinander“</h4>
                            </div> */}


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    Jede Wohngruppe verfügt über eine eigene Küche für gemeinsame Mahlzeiten und
                                    ein Wohnzimmer zum Klönen, TV schauen etc. Ein Außenbereich mit Teichanlage,
                                    Pavillons und großer Rasenfläche ist besonders in den Sommermonaten ein
                                    beliebter Treffpunkt für Bewohner und Angehörige zum Grillen, Fußballspielen und
                                    Entspannen.
                                </p>
                            </div>

                        </div>
                    </div>
                </>



                {/*      <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div>
                </> */}

                {/*    <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </> */}

                <>
                    <div className="container p-0 mt-4">
                        <div className="row m-0">

                            <div className="col-12 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartnerin
                                    </div>
                                    <div className="col-12 col-md-6 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>


                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Petra Schütte</h3>
                                            <p className="m-0"><Icon icon="Phone" /> 05751-89 104 630</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751-89 104 629</p>
                                            <p className="m-0"><Icon icon="Mail" /> <small>p.schuette@lebenshilfe-rinteln.de</small></p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>

            </div>
        </>);
    }
}

export default Alexandra;