import React from 'react';
import { Link } from "react-router-dom";
import Icon from './icons';
import { List, ListItem, ListItemText, Collapse, ListItemIcon, Divider } from '@material-ui/core';

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false }
    }

    handleClick() {
        this.setState({ open: !this.state.open });
    }

    render() {
        if (this.props.item.props) {
            return <>
                <ListItem button onClick={() => this.handleClick()} className="text-info">
                    {this.props.item.icon ? <ListItemIcon><Icon icon={this.props.item.icon} style={{ color: "#006eb3" }} /></ListItemIcon> : ""}
                    <ListItemText  ><span className="ff-2">{this.props.item.text}</span></ListItemText>
                    {this.state.open ? <Icon icon="ExpandLess" /> : <Icon icon="ExpandMore" />}
                </ListItem>
                <Divider />

                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {this.props.item.props.map((e) => <CallBack item={e} setNavState={this.props.setNavState} />)}
                    </List>
                </Collapse>
            </>;
        }
        else return <>
            <List component="div" disablePadding className="text-info">
                <Link to={this.props.item.path} onClick={() => this.props.setNavState({ drawerOpen: false })} >
                    <ListItem button className="ml-auto">
                        {this.props.item.icon ? <ListItemIcon><Icon icon={this.props.item.icon} style={{ color: "#006eb3" }} /></ListItemIcon> : ""}
                        <ListItemText  ><span className="ff-2">{this.props.item.text}</span></ListItemText>
                    </ListItem>
                </Link>
                <Divider />
            </List>
        </>

    }
}

class CallBack extends React.Component {
    render() {
        return <MobileMenu {...this.props} />;
    }
}
export default MobileMenu;








