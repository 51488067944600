import React from 'react'
import './css/bootstrap.css';
import './css/config.css';
import './css/index.css';
import './css/responsive.css';
import './css/animate.css';

import { BrowserRouter as Router, Switch, Route /* Link  */ } from "react-router-dom";

// Components
import Nav from './components/nav';
import Footer from './components/footer';
import Cookies from './components/cookies';

// Seiten 
import Aktuelles from './seiten/aktuelles';
import Alexandra from './seiten/alexandra';
import AmbulandBetreutesWohnen from './seiten/ambulant-betreutes-wohnen';
import Antonia from './seiten/antonia';
import BMH from './seiten/BMH';
import Datenschutz from './seiten/datenschutz';
import Fruehforderung from './seiten/frühförderung';
import HausAmWaldkater from './seiten/haus-am-waldkater';
import Heilpedagogischekindergarden from './seiten/heilpädagogische-kindergarden';
import Start from './seiten/index';
import Impressum from './seiten/impressum';
import IntegrativeKindergartenGruppe from './seiten/integrative-kindergarten-gruppe';
import Katharina from './seiten/katharina';
import Kindergarten from './seiten/kindergarten';
import Kontakt from './seiten/kontakt';
// import Fahrdienst from './seiten/fahrdienst';
import Krippe from './seiten/krippe';
import SchuleAmWaldkater from './seiten/schule-am-waldkater';
import Victoria from './seiten/victoria';
import Wekfeuerwehr from './seiten/wekfeuerwehr';
import Stellenangebote from './seiten/stellenangebote';
import EigeneWohnung from './seiten/eigeneWohnung';
import Wohnen from './seiten/wohnen';
import Wohntraining from './seiten/wohntraining';
import Verein from './seiten/verein';
import StellenangeboteTv from './seiten/stellenangebote_tv';
import StellenangeboteTv2 from './seiten/stellenangebote_tv2';
import TagespflegeAmWaldkater from './seiten/tagespflegeAmWaldkater';
import Therapiezentrum from './seiten/therapiezentrum';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileVersion: false
    }

    /* Alle 2 Stunden die Seite neu laden, wegen TV-App */
    setTimeout(() => window.location.reload(), 7000000);
  }

  componentDidMount() {
    window.onresize = (e) => {
      if (e.target.innerWidth < 1000) {
        if (this.state.mobileVersion === true) {
          return;
        }
        this.setState({ mobileVersion: true });
      } else {
        if (this.state.mobileVersion === false) {
          return;
        }
        this.setState({ mobileVersion: false });
      }
    };

    if (window.innerWidth < 1000) {
      this.setMyState({ mobileVersion: true })
    }
  }
  setMyState(state) {
    this.setState(state);
  }

  checkURL() {
    const path = String(window.location.pathname).toLowerCase();
    return path.toLowerCase().includes("/stellenangebote_tv");
  }

  render() {
    const disableNavAndFoot = this.checkURL();

    return (

      <Router>
        {
          disableNavAndFoot ? "" :
            <Nav {...this.props} mobileVersion={this.state.mobileVersion} />
        }

        <div style={disableNavAndFoot ? {} : { marginTop: "70px" }} className=" ff-2 ">
          <Switch>
            <Route exact path="/Aktuelles"><Aktuelles {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Alexandra"><Alexandra {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/AmbulandBetreutesWohnen"><AmbulandBetreutesWohnen {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Antonia"><Antonia {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/BMH"><BMH {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Datenschutz"><Datenschutz {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Fruehforderung"><Fruehforderung {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/HausAmWaldkater"><HausAmWaldkater {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Heilpedagogischekindergarden"><Heilpedagogischekindergarden {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Impressum"><Impressum {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/IntegrativeKindergartenGruppe"><IntegrativeKindergartenGruppe {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Katharina"><Katharina {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Kindergarten"><Kindergarten {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Kontakt"><Kontakt {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            {/*  <Route exact path="/Fahrdienst"><Fahrdienst {...this.props} mobileVersion={this.state.mobileVersion} /></Route> */}
            <Route exact path="/Krippe"><Krippe {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/SchuleAmWaldkater"><SchuleAmWaldkater {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Victoria"><Victoria {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Werkfeuerwehr"><Wekfeuerwehr {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Stellenangebote"><Stellenangebote {...this.props} mobileVersion={this.state.mobileVersion} /></Route>

            <Route exact path="/Stellenangebote_tv2"><StellenangeboteTv2 {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Stellenangebote_tv"><StellenangeboteTv {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/EigeneWohnung"><EigeneWohnung {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Wohnen"><Wohnen {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Wohntraining"><Wohntraining {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/Verein"><Verein {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/tagespflegeAmWaldkater"><TagespflegeAmWaldkater {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/therapiezentrum"><Therapiezentrum {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
            <Route exact path="/"><Start {...this.props} mobileVersion={this.state.mobileVersion} /></Route>

            <Route exact path="*"><Start {...this.props} mobileVersion={this.state.mobileVersion} /></Route>
          </Switch>
        </div>

        {
          disableNavAndFoot ? "" :
            <>
              <Footer items={this.props.config.startMenu} />
              <Cookies />
            </>
        }
      </Router>
    );
  }
}
export default App;