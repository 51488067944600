import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const materialStyle = {
    indicator: {
        // backgroundColor: 'transparent',
        // height: "100%",
        // width: "100%",
        border: "3px solid #006fb3",
        backgroundColor: "#006fb3"
        // color: "white",
    },
    scroller: {
        // backgroundColor: "#006fb3",
        // color: "white"
    }
}

class MaterialTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: 0 }
    }

    getContents(tabs) {
        let contents = {};
        for (let i = 0; i < tabs.length; i++) {
            contents["Tab" + i] = tabs[i].content;
        }
        return contents;
    }

    render() {
        return (<>
            <Tabs
                classes={{
                    indicator: this.props.classes.indicator,
                    scroller: this.props.classes.scroller,
                }}
                value={this.state.value}
                onChange={(e, value) => this.setState({ value: value })}
                variant="fullWidth"
                orientation={this.props.mobileVersion ? "vertical" : "horizontal"}
            >
                {this.props.tabs.map((item, index) => <Tab key={index} label={item.title} />)}
            </Tabs>
            <div className="p-2 mt-1 border-top ">{this.getContents(this.props.tabs)["Tab" + this.state.value]}</div>
        </>);
    }
}

MaterialTab.propTypes = {
    tabs: PropTypes.array
    // tabs --> [{title:"test",content:"<h1>hallo</h1>"}]
};

export default withStyles(materialStyle)(MaterialTab);