import React from 'react';
import * as materialIcons from '@material-ui/icons';
import * as faIcons from 'react-icons/fa';
import * as bsIcons from 'react-icons/bs';
import * as aiIcons from 'react-icons/ai';
import * as biIcons from 'react-icons/bi';
import * as diIcons from 'react-icons/di';
import * as fiIcons from 'react-icons/fi';
import * as fcIcons from 'react-icons/fc';
import * as giIcons from 'react-icons/gi';
import * as goIcons from 'react-icons/go';
import * as grIcons from 'react-icons/gr';
import * as hiIcons from 'react-icons/hi';
import * as imIcons from 'react-icons/im';
import * as ioIcons from 'react-icons/io';
import * as riIcons from 'react-icons/ri';
import * as siIcons from 'react-icons/si';
import * as tiIcons from 'react-icons/ti';
import * as vscIcons from 'react-icons/vsc';
import * as wiIcons from 'react-icons/wi';
import * as cgIcons from 'react-icons/cg';

class Icons extends React.Component {

    render() {

        let style = Object.assign({}, { fontSize: "1.5rem" }, this.props.style)

        if (materialIcons[this.props.icon]) {
            return React.createElement(materialIcons[this.props.icon].type, { style: style });
        }
        if (faIcons[this.props.icon]) {
            return React.createElement(faIcons[this.props.icon], { style: style });
        }
        if (bsIcons[this.props.icon]) {
            return React.createElement(bsIcons[this.props.icon], { style: style });
        }
        if (aiIcons[this.props.icon]) {
            return React.createElement(aiIcons[this.props.icon], { style: style });
        }
        if (biIcons[this.props.icon]) {
            return React.createElement(biIcons[this.props.icon], { style: style });
        }
        if (diIcons[this.props.icon]) {
            return React.createElement(diIcons[this.props.icon], { style: style });
        }
        if (fiIcons[this.props.icon]) {
            return React.createElement(fiIcons[this.props.icon], { style: style });
        }
        if (fcIcons[this.props.icon]) {
            return React.createElement(fcIcons[this.props.icon], { style: style });
        }
        if (giIcons[this.props.icon]) {
            return React.createElement(giIcons[this.props.icon], { style: style });
        }
        if (goIcons[this.props.icon]) {
            return React.createElement(goIcons[this.props.icon], { style: style });
        }
        if (grIcons[this.props.icon]) {
            return React.createElement(grIcons[this.props.icon], { style: style });
        }
        if (hiIcons[this.props.icon]) {
            return React.createElement(hiIcons[this.props.icon], { style: style });
        }
        if (imIcons[this.props.icon]) {
            return React.createElement(imIcons[this.props.icon], { style: style });
        }
        if (ioIcons[this.props.icon]) {
            return React.createElement(ioIcons[this.props.icon], { style: style });
        }
        if (riIcons[this.props.icon]) {
            return React.createElement(riIcons[this.props.icon], { style: style });
        }
        if (siIcons[this.props.icon]) {
            return React.createElement(siIcons[this.props.icon], { style: style });
        }
        if (tiIcons[this.props.icon]) {
            return React.createElement(tiIcons[this.props.icon], { style: style });
        }
        if (vscIcons[this.props.icon]) {
            return React.createElement(vscIcons[this.props.icon], { style: style });
        }
        if (wiIcons[this.props.icon]) {
            return React.createElement(wiIcons[this.props.icon], { style: style });
        }
        if (cgIcons[this.props.icon]) {
            return React.createElement(cgIcons[this.props.icon], { style: style });
        }

        return "";
    }
}

export default Icons;