import React from 'react';
import { Link } from "react-router-dom";
import MaterialTab from '../components/materialTab';

import Carusel from '../components/carusel';
import Icon from '../elements/icons';
// import Gallerie from '../components/gallerie';
import caruselBilder from '../css/image/wohnen/carusel';
import Bild from '../css/image/wohnen/1.JPG';
import Menu from '../components/menu';


const tabContents = [
    {
        title: "Unsere Bewohnervertretung",
        content: <div className="mt-1 text-secondary">
            <h4>Unsere Bewohnervertretung</h4>
            <div className="d-flex">

                <p>
                    Besteht aus 5 Bewohnern – diese sind Ansprechpartner für alle Belange der Bewohner wie Anregungen oder Beschwerden.
                    Sie trifft sich regelmäßig und nimmt an überregionalen Treffen teil. Eine Mitarbeiterin (Assistentin der BV) unterstützt sie dabei.
                </p>


            </div>
        </div>
    },
    {
        title: "Unsere interne Tagesstruktur",
        content: <div className="mt-1 text-secondary">
            <h4>Unsere interne Tagesstruktur</h4>
            <div className="d-flex">

                <p>
                    Für Bewohner, die das Rentenalter erreicht haben oder aus gesundheitlichen Gründen nicht mehr arbeiten können bieten wir eine Tagesstruktur an.
                    Vielfältige Beschäftigungsangebote orientieren sich an den Wünschen und Interessen der Bewohner und beziehen Freizeitangebote im näheren Umfeld mit ein.
                </p>


            </div>
        </div>
    },
    {
        title: "Voraussetzungen und Kostenübernahme",
        content: <div className="mt-1 text-secondary">
            <h4>Voraussetzungen und Kostenübernahme</h4>
            <div className="d-flex">

                <ul>
                    <li>Vorliegen einer vorrangig geistigen Beeinträchtigung, Entwicklungsverzögerung, Intelligenzminderung</li>
                    <li>vollendetes 18. Lebensjahr</li>
                    <li>Bedarfsermittlung nach Antragstellung durch den Fachdienst der Eingliederungshilfe</li>
                    <li>Kostenübernahme durch den Sozialhilfeträger gemäß SGB IX</li>
                </ul>
            </div>
        </div>
    },
    {
        title: "Unser Team",
        content: <div className="mt-1 text-secondary">
            <h4>Unser Team</h4>
            <div className="d-flex">
                <ul>
                    <li>Heilpädagogen*in</li>
                    <li>Heilerziehungspfleger*in</li>
                    <li>Altenpfleger*in</li>
                    <li>Gesundheits- und Krankenpfleger*in</li>
                    <li>Erzieher*in</li>
                    <li>Heilerziehungshelfer/in, Sozialassistenten*in</li>
                    <li>Praktikanten*in der HEP-Schulen</li>
                    <li>Betreuungskräfte</li>
                </ul>
            </div>
        </div>
    }
]
class Wohnen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* 
    
        createPhotos(data) {
            let photos = [];
            for (let i = 0; i < data.length; i++) {
                let item = {
                    src: data[i],
                    width: 10,
                    height: 15
                }
                photos.push(item);
            }
            return photos;
        }
    
     */



    render() {
        window.scrollTo(0, 0);
        // let photos = this.createPhotos(require('../css/image/schuleWaltkater/content')());
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Wohnen</h3>
                        <Carusel bilder={caruselBilder()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>



                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Wohnen</h3>
                                    <p className="text-secondary">
                                        Wohnen beinhaltet nicht nur Versorgung, Unterkunft und Verpflegung. Wohnen
                                        bedeutet Geborgenheit und Eigenständigkeit, Leben in der Gemeinschaft aber auch
                                        private Rückzugsmöglichkeit und Individualität. Menschen mit geistiger
                                        Beeinträchtigung sollen so normal wie möglich leben und dazu jede Hilfe bekommen,
                                        die sie benötigen.
                                    </p>
                                    <h5 className="text-success ff-3">mit umfassender Assistenz</h5>
                                    <p className="text-secondary">
                                        In unseren Wohngruppen leben Menschen die eine umfassende Betreuung in ihrer alltäglichen Lebensführung benötigen – daher ist hier zu jeder Zeit ein Team anwesend.
                                        Unsere Wohnbereiche sind auf die Bedürfnisse unserer Bewohner zugeschnitten – große Wohnküchen und eigene Außenbereiche mit Garten zum miteinander Leben.
                                        Alle barrierefreien Bereiche sind mit großen Pflegebädern ausgestattet. Jeder Bewohner kann seinen Lebensraum individuell gestalten – alle Zimmer besitzen
                                        vorbereitete TV-, Telefon- und Internetanschlüsse. Aufgrund der stadtnahen Lage sind Geschäfte, Banken, Ärzte auch fußläufig gut erreichbar.
                                        Das große Außengelände bietet Bewohnern und ihren Angehörigen Platz für Freizeitaktivitäten, Spaziergänge usw.
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mt-4 p-0 pl-2 rounded">
                                <img className="shadow-sm rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />
                            </div>

                            {/*  <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>„Miteinander Füreinander“</h4>
                            </div> */}


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    Unsere Ziele in der Wohngruppe sind Erhalt und Förderung von Fähigkeiten,
                                    Erwerb von Alltagskompetenzen und Ausbau von sozialen Kontakten.
                                    Für jeden Bewohner ganz persönlich in kleinen Schritten.
                                </p>
                            </div>

                        </div>
                    </div>
                </>


                {/*      <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div>
                </> */}

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6  bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-6 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>

                                            <img src={require("../css/image/ansprechpartner/marco.png")} width="200px" height="auto" alt="" />
                                            {/* <Icon icon="FcBusinesswoman" style={{ fontSize: "10rem" }} /> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Marco Reinking</h3>
                                            <p className="m-0 text-dark"><b>Einrichtungsleitung Wohnen</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751- 89 104 600 </p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751- 89 104 629 </p>
                                            <p className="m-0"><Icon icon="Mail" /> m.reinking@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">

                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/wohnen/Leichte Sprache.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Leichte Sprache</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/wohnen/Leitbild.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Leitbild</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/wohnen/Checkliste Aufnahme.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Checkliste für Aufnahmegespräch</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>

            </div>
        </>);
    }
}

export default Wohnen;