import React from 'react';
import { Link } from "react-router-dom";

// Elements
// import Icon from '../elements/icons';

import insta from "../css/image/instagram.png"
import facebook from "../css/image/facebook.png"
import logo from "../css/image/logo.jpg"
import { Button } from '@material-ui/core';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.items = [];
    }




    componentDidMount() {
        this.createItems(this.props.items);
        this.setItems(this.items);
    }
    createItems(items) {
        for (const item of items) {
            if (!item.props || !item.props.length) {
                this.items.push(item);
            } else {
                this.createItems(item.props);
            }
        }
    }

    setItems(items) {
        this.setState({ items });
    }

    openNewWindow(link) {
        window.open(link, '_blank', 'noopener,noreferrer');
    }



    render() {
        return (
            <div className="bg-white">
                <div className="container footer pb-2 pl-3 pr-3 ">
                    <div className="row m-0 text-secondary">

                        <div className="col-12 col-md-4 mt-3 d-flex">
                            <img src={logo} className="m-auto" alt="" style={{ maxWidth: "260px" }} width="100%" height="auto" />
                        </div>

                        <div className="col-12 col-md-4 mt-3">
                            <p className="text-secondary border-bottom ff-3">Informationen</p>
                            <div className="d-flex flex-column">
                                <Link className="mt-2" to={"/kontakt"}>Kontakt</Link>
                                <Link className="mt-2" to={"/impressum"}>Impressum</Link>
                                <Link className="mt-2" to={"datenschutz"}>Datenschutz</Link>
                                <Link className="mt-2" to={"/stellenangebote"}>Stellenangebote</Link>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 mt-3">
                            <p className="text-secondary border-bottom ff-3">Soziale Medien</p>
                            <div className="sozialMedia d-flex justify-content-around align-items-center">
                                <div onClick={() => this.openNewWindow("https://www.facebook.com/LebenshilfeRinteln")}>
                                    <Button><img src={facebook} alt="" width={"75px"} height="auto" /></Button>
                                </div>
                                <div onClick={() => this.openNewWindow("https://instagram.com/lebenshilfe_rinteln_e.v?igshid=YmMyMTA2M2Y=")}>
                                    <Button><img src={insta} alt="" width={"80px"} height="auto" /></Button>
                                </div>
                            </div>
                            <p className="ff-2 text-center mt-2">Besuchen Sie uns auf Facebook oder Instagram</p>
                        </div>

                    </div>
                </div>

                <div className="col-12 text-center bg-info text-white">
                    <p className="m-0">© {new Date().getFullYear()} www.lebenshilfe-rinteln.de</p>
                </div>
            </div>
        );
    }
}

export default Footer;