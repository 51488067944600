import React from 'react';
import { Link } from "react-router-dom";
import MaterialTab from '../components/materialTab';

import Carusel from '../components/carusel';
import Icon from '../elements/icons';
import Gallerie from '../components/gallerie';

import Menu from '../components/menu';
import ABild from "../css/image/ansprechpartner/karina.png";

// Download 
import PDF_lehrplan from '../downloads/schuleAmWaldkater/Unterrichtsinhalte.pdf';


const tabContents = [
    {
        title: "Klassenstruktur",
        content: <div className="mt-1 text-secondary">
            <h4>Klassenstruktur</h4>
            <div className="d-flex">
                <p>
                    <b>
                        Primarbereich<br />
                        Sekundarbereich I<br />
                        Sekundarbereich II
                    </b>
                </p>
                <p className="ml-3">
                    1. – 4. Klasse<br />
                    5. – 9. Klasse<br />
                    10. – 12 Klasse
                </p>
            </div>

            <p>Auf Antrag ist eine Schulzeitverlängerung möglich (13. Schuljahr).</p>
            <p>
                Grundlage unseres Unterrichts ist das „Kerncurriculum für den Förderschwerpunkt Geistige
                Entwicklung“ des Niedersächsischen Kultusministeriums (2016 und 2019).
                Unsere Schule vermittelt nicht nur Kulturtechniken, soziale Kompetenzen und
                lebenspraktische Fähigkeiten sondern bereitet die Schüler*innen auf die Teilhabe an der
                Gesellschaft vor.
            </p>
        </div>
    },
    {
        title: "Unser Team",
        content: <div className="p-3 text-secondary ">
            <h4>Unser Team</h4>
            <ul>
                <li>Dipl. Heilpädagogen*/Dipl. Sozialpädagogen*</li>
                <li>Heilpädagogen* mit zertifizierter unterrichtsbezogener Zusatzqualifikation</li>
                <li>Heilerziehungspfleger*</li>
                <li>Erzieher*</li>
                <li>Ergotherapeut*</li>
                <li>päd. Hilfskräfte* und Praktikanten*</li>
                <li>Berufspraktikanten*</li>
                <li>Schulhund Pelle</li>
            </ul>
        </div>
    },
    {
        title: "Aufnahmekriterien und Kosten",
        content: <div className="p-3 text-secondary">

            <h6>Aufnahmekriterien und Kosten </h6>
            <ul>
                <li>Vorliegen eines Bedarfs an sonderpädagogischer Unterstützung im Förderschwerpunkt Geistige Entwicklung der Niedersächsischen Landesschulbehörde</li>
                <li>Bedarfsermittlung durch den Fachdienst Eingliederungshilfe (B.E.Ni.) des Landkreises Schaumburg</li>
                <li>Kostenübernahme durch den örtlichen Sozialhilfeträger gemäß §§ 75, 99, ff. SGB IX</li>
            </ul>


        </div>
    },
    {
        title: "Öffnungszeiten",
        content: <div className="p-3 text-secondary">
            <h5>Öffnungszeiten:</h5>
            <p>7.30 Uhr – 16.00 Uhr (freitags 13.00 Uhr)</p>
            <h6>Unterrichtszeiten:</h6>
            <p>8.15 Uhr – 15.00 Uhr (freitags 13.00 Uhr)</p>
            <p>
                Flexible Ferienzeiten, während der Schulferien in Niedersachsen max. 30 Tage geschlossen.
                Wir bieten eine Ferienbetreuung an.
            </p>
        </div>
    },
    {
        title: "Therapien und Angebote",
        content: <div className="p-3 text-secondary">
            <h6 className="mt-3">Therapien und Angebote</h6>

            <div className="row m-0">

                <div className="col-12 col-md-6">
                    <ul>
                        <li>Sprachtherapie</li>
                        <li>Physiotherapie</li>
                        <li>Ergotherapie</li>
                        <li>Sportabzeichen</li>
                        <li>Schwimmabzeichen</li>
                        <li>Therapeutisches/Heilpädagogisches Reiten</li>
                        <li>Praktika in Arbeitsbereichen</li>
                        <li>Snoezelen</li>
                    </ul>
                </div>


                <div className="col-12 col-md-6">
                    <ul>
                        <li>Basale Stimulation</li>
                        <li>Wahrnehmungsförderung</li>
                        <li>Schüler-Eltern-Veranstaltungen</li>
                        <li>Schulfeste, Discoabende</li>
                        <li>Elternabende</li>
                        <li>Elternsprechtage</li>
                        <li>Zusammenarbeit mit dem Elternrat</li>
                        <li>Konfirmandenunterricht durch einen Pastor und Konfirmation in der Kirche</li>
                    </ul>
                </div>
                <div className="col-12">
                    <p>
                        Grundlage unseres Unterrichts ist das „Kerncurriculum für den Förderschwerpunkt Geistige
                        Entwicklung“ des Niedersächsischen Kultusministeriums (2016 und 2019).
                        Unsere Schule vermittelt nicht nur Kulturtechniken, soziale Kompetenzen und
                        lebenspraktische Fähigkeiten sondern bereitet die Schüler*innen auf die Teilhabe an der
                        Gesellschaft vor.
                    </p>

                </div>

            </div>


        </div >
    },
    {
        title: "Schulhund Pelle",
        content: <div>
            <h5 className="mt-3">Schulhund Pelle</h5>
            <p>Hallo – Ich stelle mich vor:</p>
            <p>
                Ich bin auch ein Mitarbeiter in der Schule am Waldkater, arbeite als Schulhund und bin die
                „Attraktion“ schlechthin.
                Mein Name ist Pelle, ich bin am 10.04.2018 geboren und lebe bei meinem Frauchen, die in
                der Schule am Waldkater als Klassenleitung arbeitet.
            </p>
            <p>
                Meine Hunderasse heißt „Beagle“, ich habe lange Ohren, mein Fell hat die schönen Farben schwarz-braun-weiß und ist sehr weich. Gestreichelt werden,
                gefällt mir besonders gut. Die Schüler lieben mich. Im Unterricht stelle ich den Schülern z.B. Rechenaufgaben, indem ich einen Würfel werfe oder ich ziehe Farbkarten oder Namenskarten, um den Schülern eine Schulaufgabe zu stellen.
                Am liebsten esse ich Hundekekse und spiele gern. Meine Ausbildung zum Schulhund habe ich in Hannover mit Bravour bestanden und freue mich jedes Mal auf meine Arbeit in der Schule am Waldkater.
            </p>
        </div>
    }
]
class SchuleAmWaldkater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }



    createPhotos(data) {
        let photos = [];
        for (let i = 0; i < data.length; i++) {
            let item = {
                src: data[i],
                width: 10,
                height: 15
            }
            photos.push(item);
        }
        return photos;
    }





    render() {
        window.scrollTo(0, 0);
        let photos = this.createPhotos(require('../css/image/schuleWaltkater/content')());
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Schule am Waldkater</h3>
                        <Carusel bilder={require("../css/image/schuleWaltkater/carusel")()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Schule am Waldkater</h3>
                                    <h5 className="text-success ff-3">„Der Weg ist das Ziel“</h5>
                                    <p className="text-secondary">
                                        Wir sind eine nach Schulgesetz (§164 Nds.) anerkannte heilpädagogisch orientierte
                                        schulische Ganztagseinrichtung für Kinder und Jugendliche mit Beeinträchtigungen
                                        im Bereich der geistigen Entwicklung. Die Schule am Waldkater (anerkannte
                                        Tagesbildungsstätte) ist ein Bereich von weiteren pädagogischen und pflegerischen
                                        Angeboten auf dem Gelände der Lebenshilfe Rinteln e.V. Unser Leitspruch „Der Weg
                                        ist das Ziel“ steht für unsere heilpädagogische Arbeit, Begleitung und Förderung der
                                        Schüler*innen. Die ganzheitliche Förderung wird individuell auf jeden Schüler/jede
                                        Schülerin und deren jeweiligen Bedürfnisse ausgerichtet.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={require('../css/image/schuleWaltkater/1.jpg')} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />

                            <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>„Miteinander Füreinander“</h4>
                            </div>


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    Unsere Schüler*innen absolvieren ihre Schulpflicht von 12 Jahren in unserer Schule. Auf dem „Weg“ in der Schulzeit sind wir nicht nur Lehrkräfte und Wegbegleiter, sondern vor allem Vertrauenspersonen,
                                    bis das „Ziel“ erreicht ist.
                                    Unsere familiäre Atmosphäre bietet unseren Schüler*innen nicht nur ein angenehmes Ambiente, sondern ein liebevolles Umfeld, in dem es sich mit Spaß und Motivation lernen lässt.
                                    Zudem kann unsere Schule mit einem besonderen Mitarbeiter aufwarten – unser Schulhund „Pelle“. Pelle ist ein, speziell für die Arbeit und Begleitung in Schulen ,
                                    ausgebildeter Beagle. Er ist Lebensfreude auf 4 Pfoten und bringt ein ganz besonderes Flair in die Unterrichtsräume.
                                </p>
                            </div>

                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                </>
                <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div>

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6  bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-6 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Karina Olschewski</h3>
                                            <p className="m-0 text-dark"><b>Pädagogische Leitung</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751- 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751- 89 104 499 </p>
                                            <p className="m-0"><Icon icon="Mail" /> schuleamwaldkater@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>


                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">

                                        <a className="col-12 mt-3 d-flex" href={PDF_lehrplan} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Unterrichtsinhalte</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </>

            </div>
        </>);
    }
}

export default SchuleAmWaldkater;