import React from 'react';

// Material
import { Select, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Elements
import Icon from '../elements/icons';

import Menu from '../components/menu';
class Kontakt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ABTEILUNG: "",
            alert: ""
        };
        this.values = {};
    }


    handleMenu(e) {
        this.setState({ ABTEILUNG: e.target.value });
        this.values[e.target.name] = e.target.value;
    }

    setValues(e) {
        this.values[e.target.name] = e.target.value;
        console.log(this.values);
    }

    sendMail(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("betref", this.values.TITEL);
        formData.append("mail", `${this.values.EMAIL} \n${this.values.NAME} ${this.values.NACHNAME}`);
        formData.append("abteilung", this.values.ABTEILUNG);
        formData.append("text", this.values.TEXT);
        fetch('https://lebenshilfe-rinteln.de/sendMail.php', {
            method: "POST",
            body: formData
        })
            .then((response) => {
                return response.json()
            })
            .then(() => {
                this.form.reset();
                this.setState({ alert: <Alert className="animate__animated animate__headShake" variant="filled" severity="success"><h5>E-Mail wurde erfolgreich gesendet.</h5>Wir bedanken uns für Ihre Nachricht. Sie werden bald eine Rückmeldung erhalten.</Alert>, ABTEILUNG: "" });
            })
            .catch(() => this.setState({ alert: <Alert className="animate__animated animate__headShake" variant="filled" severity="error"><h5>E-Mail könnte nicht gesendet werden</h5>Überprüfen Sie Ihre Internetverbindung oder rufen Sie uns an, um persönlich einen Kontakt herzustellen.</Alert> }))
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <div className="text-secondary p-3 animate__animated animate__slideInDown" style={{ margin: "90px 0 0 0 " }}>
                    <div className="container bg-white mapDiv">
                        <div className="p-5 text-dark">
                            <h2 className="text-center ">Kontakt <Icon icon="Phone" /></h2>
                        </div>
                    </div>

                    <div className="container bg-white pt-5">
                        <div className="row m-0 ">

                            <form onSubmit={(e) => this.sendMail(e)} className="row m-0 col-12 col-md-7" ref={(el) => this.form = el}>

                                <div className="col-md-6 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">Name</p>
                                    <input
                                        type="text"
                                        required
                                        name="NAME"
                                        className="w-100 border p-3"
                                        onChange={(e) => this.setValues(e)}
                                    />
                                </div>

                                <div className="col-md-6 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">Nachname</p>
                                    <input
                                        type="text"
                                        required
                                        name="NACHNAME"
                                        className="w-100 border p-3"
                                        onChange={(e) => this.setValues(e)}
                                    />
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">Titel</p>
                                    <input
                                        type="text"
                                        required
                                        name="TITEL"
                                        className="w-100 border p-3"
                                        onChange={(e) => this.setValues(e)}
                                    />
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">E-Mail</p>
                                    <input
                                        type="email"
                                        required
                                        name="EMAIL"
                                        className="w-100 border p-3"
                                        onChange={(e) => this.setValues(e)}
                                    />
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">Abteilung</p>
                                    <Select
                                        style={{ borderColor: "transparent" }}
                                        name="ABTEILUNG"
                                        required={true}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.ABTEILUNG}
                                        onChange={(e) => this.handleMenu(e)}
                                    >
                                        {this.props.config.abteilungen.map((item, i) => <MenuItem key={i} value={item.mail} >{item.name}</MenuItem>)}
                                    </Select>
                                    <span className="abteilungText ml-2">an welcher Abteilung soll die Nachricht gesendet werden?</span>
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    <p className="text-info m-0">Text</p>
                                    <textarea
                                        type="text"
                                        style={{ minHeight: "150px" }}
                                        required
                                        name="TEXT"
                                        className="w-100 border p-3"
                                        onChange={(e) => this.setValues(e)}
                                    />
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    {this.state.alert}
                                </div>

                                <div className="col-12 mt-1 pt-2 pb-2">
                                    <button className="w-100 submitButton">Senden <Icon icon="Send" /></button>
                                </div>
                            </form>


                            <div className="col-12 col-md-5 pt-3 text-center mt-2">

                                <div className="p-3">
                                    <p className="m-0">Sie haben Fragen, oder möchten die Lebenshilfe Rinteln e.V. näher kennenlernen, dann nutzen Sie unser Kontaktformular.</p>
                                </div>

                                <div className="p-3">
                                    <p className="m-0"><Icon icon="Map" /> <b>Adresse</b> </p>
                                    <p className="m-0">Lebenshilfe Rinteln e.V.</p>
                                    <p className="m-0">Waldkaterallee 13</p>
                                    <p className="m-0">31737 Rinteln</p>
                                </div>
                                <div className="p-3">
                                    <p className="m-0"><b><Icon icon="Phone" /> Tel.: </b>05751- 89 104 0</p>
                                    <p className="m-0"><b><Icon icon="Print" /> Fax: </b>05751- 89 104 17</p>
                                </div>
                                <div className="p-3">
                                    <p className="m-0"><Icon icon="Email" /> <b>E-Mail</b> </p>
                                    <p className="m-0"><b><Icon icon="AlternateEmail" /></b> info@lebenshilfe-rinteln.de</p>
                                </div>
                                <div className="p-3">
                                    <p className="m-0"><Icon icon="Web" /> <b>Webseite</b> </p>
                                    <p className="m-0"> www.lebenshilfe-rinteln.de</p>
                                </div>

                            </div>
                        </div>
                    </div>








                </div>





            </>);
    }
}

export default Kontakt;