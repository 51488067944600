

import React from 'react';
import Speech from 'react-speech';

// Components 
import Menu from '../components/menu';


class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        window.scrollTo(0, 0)
        return (<div className="animate__animated animate__fadeIn" style={{ overflowX: "hidden" }}>

            <video className="start-video" autoPlay muted loop playsInline>
                <source src="/video1.mp4" type="video/mp4" />
                Dein Browser unterstützt das Video-Tag nicht.
            </video>



            <div className='start-menu'>
                <Menu {...this.props} />
            </div>

            <>
                <div className="text-success ff-3">
                    <div className="block-small text-center m-0">
                        <h3 className="m-auto bg-white p-3 rounded shadow-sm">(Mit)Menschen <br /> <span style={{ marginLeft: "132px" }}>für Menschen</span></h3>
                    </div>
                </div>
            </>

            <>
                <div className=" shadow-sm bg-white text-dark p-3 startText mb-3">
                    <p className="container">
                        Lebenshilfe Rinteln e.V. ist eine Einrichtung zur Betreuung und Förderung von Menschen mit speziellen Bedürfnissen.
                        Es gibt uns seit 1966. Unsere Anfänge waren in angemieteten Räumen in der Altstadt von Rinteln. 1998 zogen wir von der Ostertorstraße in die Waldkaterallee auf das Gelände des ehem.
                        Britischen Militär Hospitals. Auf Grund der Möglichkeiten auf dem Gelände konnten wir weiter expandieren und neben der Platzzahlerhöhung von Frühförderung,
                        Heilpädagogischen Kindergarten und Tagesbildungsstätte auch drei Wohnheime (alles Einrichtungen speziell für Menschen mit Beeinträchtigungen) in Betrieb nehmen.
                        Am 01.09.2006 wurde der von der Heimaufsicht genehmigte Wohnbereich für Senioren mit geistiger Beeinträchtigung mit 24 Plätzen in Betrieb genommen.
                        Am 01.09.2015 hat unser Pflegeheim "Haus am Waldkater" seinen Betrieb aufgenommen und schon nach kurzer Zeit eine sehr gute Auslastung erreicht.
                        Am 01.09.2016 wurde der Regelkindergarten Integrative "Kindertagesstätte am Waldkater" eröffnet und im Jahr 2019 um eine Krippen- sowie die Integrationsgruppe erweitert.
                        Im Jahr 2021 erfolgte der Umzug des Seniorenwohnens in das frisch umgebaute Haus Antonia. Heute werden bei uns ca. 500 Menschen in den verschiedenen Bereichen gefördert und betreut.
                    </p>

                    {this.props.mobileVersion ? "" :
                        <div className="d-flex mt-3 mb-3">
                            <div className="ml-auto mr-auto sprechen d-flex border p-2 rounded bg-secondary text-center">
                                <Speech
                                    lang="de"
                                    displayText="Vorlesen"
                                    stop
                                    rate={0.8}
                                    text={`
                                            Die Lebenshilfe Rinteln e.V. ist eine Einrichtung zur Betreuung und Förderung von Menschen mit speziellen Bedürfnissen.
                                            Es gibt uns seit 1966. Unsere Anfänge waren in angemieteten Räumen in der Altstadt von Rinteln. 1998 zogen wir von der Ostertorstraße in die Waldkaterallee auf das Gelände des ehem.
                                            Britischen Militär Hospitals. Auf Grund der Möglichkeiten auf dem Gelände konnten wir weiter expandieren und neben der Platzzahlerhöhung von Frühförderung,
                                            Heilpädagogischen Kindergarten und Tagesbildungsstätte auch drei Wohnheime (alles Einrichtungen speziell für Menschen mit Beeinträchtigungen) in Betrieb nehmen.
                                            Am 01.09.2006 wurde der von der Heimaufsicht genehmigte Wohnbereich für Senioren mit geistiger Beeinträchtigung mit 24 Plätzen in Betrieb genommen.
                                            Am 01.09.2015 hat unser Pflegeheim "Haus am Waldkater" seinen Betrieb aufgenommen und schon nach kurzer Zeit eine sehr gute Auslastung erreicht.
                                            Am 01.09.2016 wurde der Regelkindergarten Integrative "Kindertagesstätte am Waldkater" eröffnet und im Jahr 2019 um eine Krippen- sowie die Integrationsgruppe erweitert.
                                            Im Jahr 2021 erfolgte der Umzug des Seniorenwohnens in das frisch umgebaute Haus Antonia. Heute werden bei uns ca. 500 Menschen in den verschiedenen Bereichen gefördert und betreut.
                                        `}
                                />
                                <span className="mt-2 ml-1 text-white">Vorlesen</span>

                            </div>

                        </div>
                    }
                </div>
            </>


            <div className='container'>

                <div className='row mb-3'>
                    <div className="col-12 col-md-6 mt-2 shadow-sm bg-white text-dark p-3 rounded">
                        <p>Lebenshilfe Rinteln Imagefilm</p>
                        <video style={{ width: "100%", borderRadius: "10px", height: "250px" }} controls poster="poster1.png" >
                            <source src="/video1.mp4" type="video/mp4" />
                            Dein Browser unterstützt das Video-Tag nicht.
                        </video>
                    </div>

                    <div className="col-12 col-md-6 mt-2 shadow-sm bg-white text-dark p-3 rounded">
                        <p>Lebenshilfe Rinteln Kinospot</p>
                        <video style={{ width: "100%", borderRadius: "10px", height: "250px" }} controls poster='poster2.png' >
                            <source src="/video2.mp4" type="video/mp4" />
                            Dein Browser unterstützt das Video-Tag nicht.
                        </video>
                    </div>
                </div>


            </div>

        </div >
        );
    }
}

export default Start;