import React from 'react';

class AambulantBetreutesWohnen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<> Aambulant betreutes Wohnen</>);
    }
}

export default AambulantBetreutesWohnen;