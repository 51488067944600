import React from 'react';
import { Link } from "react-router-dom";

import MaterialTab from '../components/materialTab';
import Icon from '../elements/icons';
import Menu from '../components/menu';

import Carusel from '../components/carusel';

import caruselBilder from "../css/image/kindergarten/carusel";
import Bild from "../css/image/kindergarten/1.JPG";
import ABild from "../css/image/ansprechpartner/sylvia.png";

import ABild2 from "../css/image/ansprechpartner/claudia.png";



const tabContents = [

    {
        title: "Betreuungsangebot ",
        content: <div className="p-3 text-secondary ">
            <h3>Betreuungsangebot </h3>
            <div className="row m-0">
                <div className="col-12 col-md-6">

                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Integrative Kita am Waldkater Integrationsgruppe</b></p>
                        <p className="m-0 col-6 border p-1 text-center"><b>2 Kindergartengruppen</b></p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Betreuungsalter der Kinder</b></p>
                        <p className="m-0 col-6 border p-1 text-center">3 – 6  Jahre</p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Anzahl der Plätze</b></p>
                        <p className="m-0 col-6 border p-1 text-center">Eichhörnchengruppe: <br />25 Kinder<br />  Hasengruppe:<br />  10 Kinder</p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Betreuungszeiten</b></p>
                        <p className="m-0 col-6 border p-1 text-center"> 8:00 – 12:30<br />

                            8:00 – 13:30<br />
                            8:00 – 16:00
                            <br /> <br /> Sonderöffnungszeit für berufstätige Eltern:<br />  7:30 – 8:00 <br />  16:00 – 16:30</p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Besonderheiten</b></p>
                        <p className="m-0 col-6 border p-1 text-center"></p>
                    </div>
                </div>


                <div className="col-12 col-md-6">
                    <p>
                        Der Einzugsbereich für die integrative KiTa am Waldkater ist die Stadt Rinteln mit den umliegenden Ortsteilen.
                        Eine Betreuungsgebühr für Kinder ab der Vollendung des 3. Lebensjahres bis zum Schuleintritt entfällt im Land Niedersachsen. Bei einer Betreuungszeit von mehr als 8 Stunden wird eine Gebühr von 19,- € pro Stunde erhoben.
                        <br />  <br />
                        Der Mittagessenbeitrag beträgt zurzeit 64,00 €. Unter bestimmten Voraussetzungen kann eine Übernahme des Beitrages durch BuT beantragt werden.<br />  <br />
                        In der Regel schließen wir 3 Wochen in den niedersächsischen Sommerferien sowie zwischen Weihnachten und Neujahr. Weitere Schließzeiten für Brückentage oder Teamtage werden, wenn möglich langfristig vorher bekanntgegeben.
                    </p>



                </div>


            </div>

        </div>
    },



    {
        title: "Unsere pädagogische Arbeit  ",
        content: <div className="p-3 text-secondary ">
            <h3>Unsere pädagogische Arbeit  </h3>
            <div className="row m-0 ">

                <div className="col-12  mt-2">
                    <p><b>Eingewöhnung:</b></p>
                    <p>
                        Wir schaffen für Kinder einen verlässlichen Rahmen, der ihnen die Möglichkeit bietet Neues kennenzulernen und Beziehungen neu aufzubauen.
                        Dafür ist eine gute Eingewöhnung von besonderer Bedeutung.
                        Ein Elternteil oder eine andere feste Bindungsperson steht dem Kind bei der Eingewöhnung unterstützend zur Seite und vermittelt dem Kind die benötigte Sicherheit und Bereitschaft,
                        um sich auf die neue Situation einzulassen. Der Eingewöhnungsprozess orientiert sich am Kind und jedes Kind bekommt die Zeit, die es braucht.
                    </p>
                </div>
                <div className="col-12  mt-2">
                    <p><b>Unsere pädagogische Arbeit:</b></p>
                    <p>
                        Anhand von Beobachtungen im Tagesablauf gestalten wir unsere Angebote und Projekte nach den Interessen und Bedürfnissen der Kinder und in Anlehnung an das Kindergartenjahr.
                        Ziele und Schwerpunkte unserer integrativen Arbeit setzen wir durch angeleitete/ begleitende Angebote sowie Förderangebote in der Klein- oder Gesamtgruppe um.
                        Die Bildungs- und Erziehungsziele, unserer pädagogischen Arbeit orientieren sich am niedersächsischen Bildungs- und Orientierungsplan.
                        Folgende Lernbereiche werden dazu aufgegriffen:
                    </p>
                    <ul>
                        <li>Emotionale Entwicklung und soziales Lernen</li>
                        <li>Entwicklung kognitiver Fähigkeiten und die Freude am Lernen</li>
                        <li>Körper, Bewegung und Gesundheit</li>
                        <li>Sprache und Sprechen</li>
                        <li>Lebenspraktische Kompetenzen</li>
                        <li>Mathematisches Grundverständnis</li>
                        <li>Ästhetische Bildung</li>
                        <li>Natur und Umwelt</li>
                        <li>Ethische (und religiöse) Fragen / Grunderfahrungen menschlicher Existenz </li>
                    </ul>
                    <p>Beobachtungen und Dokumentationen sind ein Instrument unserer pädagogischen Arbeit und bieten die Grundlage zum Austausch mit den Eltern über die Entwicklung und Förderung ihres Kindes.</p>
                </div>
                <div className="col-12  mt-2">
                    <p><b>Unsere Kita ist ein Ort, wo Kinder</b></p>
                    <ul>
                        <li>mit Spaß und Freude spielend lernen können</li>
                        <li>ihre Kreativität ausleben können</li>
                        <li>Impulse für anregende Lern- und Entwicklungsprozesse finden</li>
                        <li>lebensbedeutsame Erfahrungen sammeln können</li>
                        <li>ein Mitspracherecht haben und gehört werden, da unser pädagogisches Personal immer ein offenes Ohr für die Bedürfnisse und Belange der Kinder hat</li>
                        <li>stark gemacht werden für ihren Lebensweg</li>
                        <li>beziehungsfähig werden und sich als Teil einer Gruppe anerkennen mit ihren persönlichen und individuellen Bedürfnissen</li>
                        <li>eine sichere, liebevolle und zugewandte Begleitung erfahren in einem konstruktiven und freundlichen Miteinander</li>
                    </ul>
                </div>
                <div className="col-12  mt-2">
                    <p><b>Das Team:</b></p>
                    <i>Pädagogisches Team </i>
                    <p className="text-info">Pädagogische Leitung und Stellvertretung</p>
                    <div className="row m-0">

                        <p><ins>Kiga Gruppe I +II::</ins></p><br />

                        Staatlich anerkannte Erzieher/-in<br />
                        Staatlich anerkannte Heilerziehungspfleger/-in<br />
                        Staatlich anerkannte Sozialassisten/-in
                    </div>
                </div>
            </div>
        </div>
    },
    {
        title: "Raumangebote ",
        content: <div className="p-3 text-secondary ">
            <h3>Raumangebote </h3>
            <p>
                Die Hasen- und Eichhörnchengruppe befinden sich in einem Nebengebäude auf dem Gelände der Lebenshilfe Rinteln e.V.. Zu den Räumlichkeiten gehören jeweils:
            </p>

            <ul>
                <li> ein spielanregender und kindgerechter Gruppenraum</li>
                <li> ein Waschraum (1x mit Wickeltisch; 1x mit Dusche)</li>
                <li> ein gruppenübergreifender Garderobenbereich</li>
            </ul>


            <p>Das anregungsreiche, bewegungsfreundliche und großzügige Außengelände mit über 120.000 m² bietet mit verschiedenen Spielplatzanlagen viel Platz für Bewegungsangebote und Spaziergänge.</p>
            <p>Zusätzlich stehen folgende gruppenübergreifende Raumangebote zur Verfügung:</p>

            <ul>
                <li>eine Psychomotorik – Halle für sportliche Angebote</li>
                <li>ein Pavillon für musisch – rhythmische Bewegungsangebote</li>
            </ul>


        </div>
    },
    {
        title: "Tagesablauf  ",
        content: <div className="p-3 text-secondary ">
            <h3>Tagesablauf  </h3>
            <p>
                Ein strukturierter Rahmen von Verlässlichkeit und Routine bietet Kindern Sicherheit und Beständigkeit und gibt ihnen eine Orientierung im pädagogischen Alltag.
                Im gesamten Vormittagsbereich und nach dem Mittagessen bleibt viel Zeit für das freie Spiel sowie für didaktisch- methodisch angeleitete Angebote. Im Spiel erobern sich Kinder aktiv ihre Welt und erweitern ihre Fähigkeiten und Kompetenzen stetig. Mindestens einmal pro Woche stehen jeder Gruppe die Psychomotorik - Halle und der Pavillon für Bewegungsangebote zur Verfügung. Außerdem bietet das große Außengelände viel Raum und Platz für verschiedene Möglichkeiten zur täglichen Bewegung für alle Gruppen.
            </p>

            <div className="row m-0 mt-3">
                <p className="m-0 col-6 border p-2 text-center"><b>Uhrzeit</b></p>
                <p className="m-0 col-6 border p-2 text-center"><b><ins>Tagesablauf Eichhörnchen- und Hasengruppe </ins></b></p>
            </div>

            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>08:00 – 09:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Bringzeit der Kinder </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 08:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">
                    Freispielzeit: Die Kinder wählen Spielort, -material, -partner und -verlauf selbst aus, es finden auch Kleingruppenangebote statt
                    gleitendes Frühstück (bis 9:30 Uhr)
                </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 09:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Angebote für die Gesamtgruppe oder gruppenübergreifend (kreative, musische, motorische, sprachliche und soziale Angebote/ Morgenkreis/ Geburtstagskreis)</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 11:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Freies Spiel auf dem Außengelände/ geplante Bewegungsangebote im Pavillon, Turnhalle oder draußen (evtl. gruppenübergreifend)</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>bis 12:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Abholzeit für Kinder ohne Mittagessen</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 12:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Mittagessen </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 13:00/13:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">
                    Für Kinder der Ganztagsbetreuung bis 16:00<br />
                    - Ruhiges Spiel<br />
                    - Mittagsruhe/Schlafphase/ Entspannung (freiwillig)
                </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>bis 13:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Abholzeit für Kinder mit Betreuungszeit bis 13:30 Uhr</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 14:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Snackphase<br />Freispiel drinnen oder draußen, Projekte oder Angebote</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>Bis 16:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Abholzeit </p>
            </div>

            <div className="mt-4">

                <b>Verpflegung:</b>
                <p>
                    Die Kinder bringen ihr eigenes Frühstück mit und haben die Möglichkeit im Vormittagsbereich zu frühstücken. Tee und Wasser stehen dabei frei zur Verfügung.
                    <br />   <br />
                    In der hauseigenen Küche wird das Mittagessen für die gesamte Einrichtung gekocht, dabei werden familiäre und religiöse Hintergründe berücksichtigt. Zum gemeinsamen Mittagessen bekommen die Gruppen regelmäßig zusätzlich frisches Gemüse und Obst.
                    <br />   <br />
                    Am Nachmittag haben die Kinder die Möglichkeit für eine Snackpause mit eigenen mitgebrachten Speisen, dazu werden Getränke sowie evtl. Obst und Gemüse gereicht
                </p>
            </div>
        </div>
    },
    {
        title: "Familien- und Elternangebote  ",
        content: <div className="p-3 text-secondary ">
            <h3>Familien- und Elternangebote  </h3>
            <div className="row m-0">
                <div className="col-12">
                    <b>Zusammenarbeit mit Familien </b>
                    <p>
                        Eine Zusammenarbeit der pädagogischen Fachkräfte mit den Eltern ist wichtig, um zum Wohl der Kinder zu handeln und gute Entwicklungsbedingungen zu schaffen. Der Dialog zwischen Eltern und pädagogischen Fachkräften dient zum Austausch von Gedanken, Informationen, Gefühlen, Erfahrungen, Ansichten, Ideen und Fragen und ist der Grundpfeiler für eine vertrauensvolle Zusammenarbeit.   <br />   <br />
                        Kinder fühlen sich wohl und zeigen Entwicklungsschritte (ein Explorationsverhalten), wenn zwischen Eltern, päd. Fachkräften und Träger eine tragfähige und partnerschaftliche Beziehung besteht, geprägt durch das gemeinschaftliche Handeln.
                    </p>
                </div>
                <div className="col-md-6">
                    <b> Möglichkeiten der Zusammenarbeit mit Familien:</b>
                    <ul>
                        <li>Aufnahmegespräche</li>
                        <li>Individuelle Absprachen zur Eingewöhnung</li>
                        <li>Elterngespräche – Entwicklungsgespräche</li>
                        <li>Elternrat</li>
                        <li>Beratungsgespräche</li>
                        <li>Eltern-Kind-Aktionen und Feste </li>
                        <li>Elternabende</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <b>Zusammenarbeit mit anderen Institutionen:</b>
                    <ul>
                        <li>Einrichtungen auf dem Gelände der Lebenshilfe Rinteln e.V.</li>
                        <li>Frühförderung</li>
                        <li>Therapeuten</li>
                        <li>Kindergartengruppen</li>
                        <li>Grundschulen</li>
                        <li>Familienhilfe</li>
                    </ul>
                </div>

            </div>



        </div>
    },
    {
        title: "Anmeldungen/ Aufnahme",
        content: <div className="p-3 text-secondary ">
            <h3>Anmeldungen/ Aufnahme</h3>
            Anmeldungen nehmen wir im gesamtem Jahr entgegen. <Link to="/kontakt"><b className="text-info"><i>Kontakt</i></b></Link><br /><br />
            Die Aufnahme des Kindes erfolgt in der Regel zu Beginn des neuen Kindergartenjahres.
            Sofern freie Plätze zur Verfügung stehen, können Kinder auch im laufenden Jahr aufgenommen werden.<br /><br />
            Wenn sie Fragen zur Anmeldung bzw. Aufnahme haben oder wenn sie sich über unsere Einrichtung in einem persönlichen Gespräch informieren möchten, nehmen Sie gerne Kontakt zu uns aus.
        </div>
    }

]

class Kindergarten extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0"> Kindergarten</h3>
                        <Carusel bilder={caruselBilder()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Integrative Kindertagesstätte am Waldkater</h3>

                                    <p className="text-secondary">
                                        Herzlich Willkommen bei der integrativen Kindertagesstätte am Waldkater.
                                        Nach langjähriger Erfahrung im Bereich der heilpädagogischen Arbeit im Kindergartenalter wurde am 01.09.2016 die erste Kindergartengruppe
                                        mit 25 Regelplätzen auf dem Gelände der Lebenshilfe Rinteln e.V. eröffnet. Diese wurde später mit einer Kleingruppe mit bis zu 10 Kindern erweitert.
                                        Im September 2019 kamen eine Krippengruppe mit 15 Plätzen und eine Integrationsgruppe mit 18 Plätzen dazu. So wurde aus dem Kindergarten die
                                        <b> integrative Kindertagesstätte am Waldkater</b>.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <h3 className="text-center">
                            „Wenn man genügend spielt, solange man klein ist,
                            trägt man Schätze mit sich,
                            aus denen man ein Leben lang schöpfen kann.“<br /><br />
                            (Astrid Lindgren)
                        </h3>
                    </div>
                </>


                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>

                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Sylvia Nebeling</h3>
                                            <p className="m-0 text-dark"><b>Pädagogische Leitung</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 499 </p>
                                            <p className="m-0"><Icon icon="Mail" /> kita@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>

                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild2} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Claudia Schrader</h3>
                                            <p className="m-0 text-dark"><b>(stellv. Pädagogische Leitung)</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 499</p>
                                            <p className="m-0"><Icon icon="Mail" /> kita@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">


                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/kindergarten/Voranmeldung.pdf')} download>
                                            <Icon icon="FaFilePdf" />  <span className="ml-2">Voranmeldung</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>

                                        {/*    <a className="col-12 mt-3 d-flex" href={require('../downloads/Text.txt')} download>
                                            <Icon icon="FaFileAlt" />  <span className="ml-2">Das ist ein test Datei</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/bild.jpg')} download>
                                            <Icon icon="Image" />  <span className="ml-2">Das ist ein test Bild</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            </div>);
    }
}

export default Kindergarten;