import React from 'react';
import { Link } from "react-router-dom";
import Icon from '../elements/icons';
import ListCollapse from './collapse';

// Material 
import { List, ListItem, ListItemIcon, ListItemText, Divider, Menu } from '@material-ui/core';

class HoverMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        };
    }

    handleClick(e) {
        this.setState({ open: !this.state.open, anchorEl: e.currentTarget });
    }
    getListItem(item, i) {
        if (item.props) {
            return <React.Fragment key={i}>
                <ListCollapse {...item} />
                {this.props.props[i + 1] ? <Divider /> : ""}
            </React.Fragment>
        }

        return <React.Fragment key={i}>
            <Link to={item.path} >
                <ListItem button>
                    {item.icon ? <ListItemIcon><Icon icon={item.icon} /></ListItemIcon> : ""}
                    <ListItemText primary={item.text} />
                </ListItem>
                {this.props.props[i + 1] ? <Divider /> : ""}
            </Link>
        </React.Fragment>
    }


    render() {
        return (<>
            <div
                className={this.props.className + " pointer d-flex"}
                style={{ height: "80px", fontSize: "large" }}
                onClick={(e) => this.handleClick(e)}
                aria-describedby={this.props.text}
            >
                {this.props.props ?
                    <p className="m-auto menuItem"><Icon icon={this.props.icon} /> {this.props.text}  <Icon icon="ArrowDropDown" /></p>
                    :
                    <Link to={this.props.path} className="w-100 h-100 d-flex">
                        <p className="m-auto menuItem"><Icon icon={this.props.icon} /> {this.props.text} </p>
                    </Link>
                }
            </div>


            {this.props.props ?
                <Menu
                    id={this.props.text}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ open: false })}
                >
                    <List>
                        {this.props.props.map((e, i) => this.getListItem(e, i))}
                    </List>
                </Menu>

                : ""
            }




        </>);
    }
}

export default HoverMenu;