/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Menu from '../components/menu';

class Verein extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>


                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-12 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Verein</h3>
                                    <p className="text-secondary">
                                        Im Jahre 1966 wurde der Verein „Lebenshilfe Rinteln e.V.“ in Rinteln
                                        gegründet.
                                        Damals schlossen sich engagierte Mitmenschen zusammen, um
                                        Menschen mit Beeinträchtigungen zu helfen. Mit einer Gruppe von 9
                                        Kindern wurde in angemieteten Räumen in der Bäckerstraße
                                        begonnen. Schon bald reichten diese Räumlichkeiten nicht mehr
                                        aus. Durch die Hilfe des Landkreises Schaumburg wurde es möglich,
                                        im „Zersenhof“ in der Ostertorstrasse mehrere Räume zu nutzen.
                                        Eine stetige Aufwärtsentwicklung begann. 1983 wurde die
                                        Tagesbildungsstätte als eine staatlich anerkannte
                                        Tagesbildungsstätte in privater Trägerschaft der Lebenshilfe Rinteln
                                        e.V. anerkannt.<br /><br />
                                        Im Sommer 1987 konnten die Frühförderung, der Heilpädagogische
                                        Kindergarten und die Tagesbildungsstätte, die 1½ Jahre in einem
                                        Gebäude der Stadt Rinteln untergebracht waren, ihre neuen
                                        Räumlichkeiten beziehen. 1990 wurde die Frühförderung in das
                                        Nachbarhaus ausgesiedelt, das der Lebenshilfe wiederum durch den
                                        Landkreis Schaumburg zur Verfügung gestellt wurde.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <p>
                            Durch das stetige Wachstum in den einzelnen Bereichen wurde das
                            Gebäude an der Ostertorstrasse zu klein. Im Jahr 1998 kaufte die
                            Lebenshilfe vom Bundesvermögensamt eine Teilfläche von 60.000
                            m<sup>2 </sup> des ehemaligen Britischen Militär Hospitals.
                            Auf diesem neuen Gelände an der Waldkaterallee konnte die
                            Tagesbildungsstätte, der Heilpädagogische Kindergarten und die
                            Frühförderung nochmals wieder erheblich erweitert werden.
                            Im November 1999 konnte der Wohnbereich „Alexandra“ für 42
                            Menschen mit Beeinträchtigungen eröffnet werden. Speziell auf
                            Rollstuhlfahrer ausgelegt nahm der zweite Wohnbereich „Victoria“
                            im Herbst 2001 mit 11 Plätzen den Betrieb auf. Zu der Zeit waren
                            beide Wohnbereiche voll belegt.
                            Das Gelände wurde im August 2002 noch einmal um den restlichen
                            Teil erweitert. Am 01.08.2002 nahm der dritte Wohnbereich
                            „Katharina“ seinen Betrieb auf und bietet nach mehreren
                            Erweiterungen 55 Plätze. Am 01.09.2006 ist der neue Bereich
                            &quot;Seniorenwohnen&quot; mit 24 Plätzen in Betrieb gegangen. Mittlerweile
                            stehen insgesamt 140 Wohnplätze zur Verfügung. Unseren Pflegebereich Haus am Waldkater haben wir am 01.09.2015 mit 45 Plätzen in Einzel- und Doppelzimmer aufgeteilt, eröffnet.
                            Am 01.09.2016 wurde der „Regelkindergarten am Waldkater“ eröffnet und 2019 durch 1 Krippengruppe und 1 Integrationsgruppe erweitert und heißt seitdem „Integrative Kindertagesstätte am Waldkater“.
                            Im Januar 2021 ist das „Seniorenwohnen“ in das voll renovierte Haus „Antonia“ gezogen
                            und wurde somit auf 31 Wohnplätze erweitert. Die 2. Krippengruppe wurde am 01.09.2022 in Betrieb genommen.
                        </p>

                    </div>
                </>

                <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-3 text-white mb-5">
                        <p>
                            Sie möchten unsere Arbeit unterstützen? Dann setzen Sie sich mit
                            uns telefonisch (05751/89 104 0) oder per
                            E-Mail (verein@lebenshilfe-rinteln.de) in Verbindung.<br /><br />
                            Spendenkonto:<br />
                            Sparkasse Schaumburg (NOLADE21SHG)<br />
                            IBAN: DE 68 2555 1480 0510 3139 35
                        </p>
                    </div>
                </>

            </div>);
    }
}

export default Verein;