import React from 'react';
import { Link } from "react-router-dom";

import MaterialTab from '../components/materialTab';
import Icon from '../elements/icons';
import Menu from '../components/menu';

import ABild from "../css/image/ansprechpartner/sylvia.png";
import Carusel from '../components/carusel';
import Bild from '../css/image/heilKindergarten/1.JPG';
let CaruselBilder = require('../css/image/heilKindergarten/carusel');
CaruselBilder = CaruselBilder();


const tabContents = [

    {
        title: <small>Betreuungsangebote</small>,
        content: <div className="p-3 text-secondary ">
            <h3>Betreuungsangebote </h3>

            <div className="row m-0 ">

                <ul className="col-md-6">
                    <li>6 heilpädagogische Gruppen mit max. 8 Kindern</li>
                    <li>ab 3 Jahre bis zur Einschulung</li>
                    <li>Betreuungszeit<br />Montag bis Donnerstag 8:30 Uhr – 15:00 Uhr<br /> Freitag 8:30 Uhr – 13:00 Uhr</li>
                    <li>Sonderöffnungszeiten von 7:30 Uhr – 8:30 Uhr und 15:00 Uhr - 16:00 Uhr</li>
                    <li>Schließungszeiten max. 30 Tage im Jahr</li>
                </ul>
                <div className="col-md-6">
                    <b>Fahrdienst</b>
                    <p>Die Kinder des heilpädagogischen Kindergartens werden von unserem Fahrdienst morgens abgeholt und am Nachmittag wieder nach Hause gebracht.</p>
                </div>
            </div>


        </div >
    },


    {
        title: <small>Therapieangebote</small>,
        content: <div className="p-3 text-secondary ">
            <h3>Therapieangebote</h3>
            <p>Während des Kindergartentages finden Therapien und individuelle Förderangebote für die Kinder statt:</p>
            <ul>
                <li>Sprachtherapie</li>
                <li>Ergo- und sensorische Integrationstherapie</li>
                <li>Physiotherapie</li>
                <li>Psychomotorik</li>
                <li>Einzel- und Kleingruppenförderung</li>
                <li>Vorschulprogramm</li>
            </ul>
        </div>
    },

    {
        title: <small>Fachlichkeit </small>,
        content: <div className="p-3 text-secondary ">
            <h3>Fachlichkeit </h3>
            <div className="row m-0">

                <div className="col-md-6">
                    <p>
                        Ausgehend von der individuellen Persönlichkeit des Kindes fördern wir seine soziale, geistige, körperliche und sinnliche Entwicklung. Dabei stehen Spiel, Spaß, Freude und Gemeinschaft im Vordergrund.
                        <br /><br />
                        Unsere Pädagogik bietet den Kindern vielfältige Erfahrungsräume, die zu Eigeninitiative herausfordern und ihre Entwicklung zu einer selbständigen Persönlichkeit fördern.
                        <br /><br />
                        Bei der Tagesplanung berücksichtigen wir die Bedürfnisse der Kinder nach Freiräumen und Geborgenheit, Bewegung und Ruhe, Spontanität und Verlässlichkeit.
                        <br /><br />
                        Jedes Kind soll eine individuelle und optimale Förderung erfahren.
                    </p>
                </div>
                <div className="col-md-6">
                    <p>Unser Team</p>
                    <ul>
                        <li>Staatlich anerkannte Heilpädagogen/-innen</li>
                        <li>Staatlich anerkannte Heilerziehungspfleger/-innen</li>
                        <li>Staatlich anerkannte Erzieher/-innen</li>
                        <li>Staatlich anerkannte Sozialassistenten/-innen</li>
                        <li>Ergotherapeuten/-innen</li>
                        <li>Sprachtherapeuten/-innen</li>
                        <li>Mitarbeiter/-innen im Bundesfreiwilligendienst</li>
                        <li>Berufspraktikanten/-innen</li>
                        <li>Physiotherapeuten/-innen</li>
                    </ul>
                </div>
            </div>

        </div>
    },
    {
        title: <small>Raumangebote</small>,
        content: <div className="p-3 text-secondary ">
            <h3>Raumangebote  </h3>
            <div className="row m-0">
                <div className="col-md-6">
                    <p>Jede heilpädagogische Gruppe verfügt über einen eigenen Bereich mit:</p>
                    <ul>
                        <li>Gruppenraum</li>
                        <li>Themenräume (Bauraum, Rollenspielraum, Entspannungsraum)</li>
                        <li>Bad mit Wickeltisch, Waschrinnen (auch zum Spielen und Experimentieren) Dusche oder Badewanne</li>
                        <li>Garderobe und Flur</li>
                        <li>Küchenzeile</li>
                        <li>Übergreifende Räume (Turnhalle, SI-Raum, Pavillon)</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <p><b>Spielplatz, Außengelände:</b></p>
                    <p>
                        Unser Kindergarten verfügt über ein großzügiges Außengelände mit altem Baumbestand, Wiesen und Wegen.
                        <br /><br />
                        An verschiedenen Spielgeräten und in Sandkästen ist genügend Platz, um den Bewegungsdrang auszuleben.
                        <br /><br />
                        Hier treffen sich alle Kinder aus den unterschiedlichen Bereichen
                    </p>
                </div>
            </div>

        </div>

    },

    {
        title: <small>Tagesablauf  </small>,
        content: <div className="p-3 text-secondary ">
            <h3>Tagesablauf  </h3>
            <p>
                Ein strukturierter Rahmen von Verlässlichkeit und Routine bietet Kindern Sicherheit und Beständigkeit und gibt ihnen eine Orientierung im pädagogischen Alltag. Im gesamten Vormittagsbereich und nach dem Mittagessen bleibt viel Zeit für das freie Spiel sowie für didaktisch- methodisch angeleitete Angebote. Im Spiel erobern sich Kinder aktiv ihre Welt und erweitern ihre Fähigkeiten und Kompetenzen stetig.
                Mindestens einmal pro Woche stehen jeder Gruppe die Psychomotorik - Halle und der Pavillon für Bewegungsangebote zur Verfügung. Außerdem bietet das große Außengelände viel Raum und Platz für verschiedene Möglichkeiten zur täglichen Bewegung für alle Gruppen
            </p>

            <div className="row m-0 mt-3">
                <p className="m-0 col-6 border p-2 text-center"><b>Uhrzeit</b></p>
                <p className="m-0 col-6 border p-2 text-center"><b><ins>Tagesablauf</ins></b></p>
            </div>

            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>08:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Ankunft der Kinder im Kindergarten (Kinder werden von den Mitarbeitern/-innen vom Bus abgeholt)<br />Gemeinsames Frühstück in den einzelnen Gruppen<br />Förderung der Selbständigkeit<br />beim Händewaschen beim Toilettengang An- und Ausziehen der Kleidung</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>09:30 </b></p>
                <p className="m-0 col-6 border p-2 text-center">Freispiel Rollenspiele nachgespielte Alltagssituationen  </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b> 10:00</b></p>
                <p className="m-0 col-6 border p-2">
                    <ul>
                        <li>Gruppenangebote/gruppenübergreifende Angebote</li>
                        <small>
                            -Wahrnehmungsübungen Rahmenthemen<br />
                            -Bilderbuchbetrachtung, Umweltorientierung, Malen und Basteln, Regelspiele<br />
                            -Einzelförderung <br />
                            -Rhythmik, Sport, Psychomotorik<br />
                            -Wassergewöhnung (Hallenbad)<br />
                            -Vorschulprogramm, Konzentrationstraining<br />
                            -Sprachtherapie (Logopädie), Ergotherapie, Physiotherapie
                        </small>

                        <li>Aufenthalt im Freien (Spielplatz, Wald)</li>
                    </ul>
                </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>12:00</b></p>
                <p className="m-0 col-6 border p-2 text-center"> Gemeinsames Mittagessen in den einzelnen Gruppen<br /><br /> Förderung der Selbständigkeit/Zähneputzen im Waschraum</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>13:00 bis 14:00 </b></p>
                <p className="m-0 col-6 border p-2 text-center"> 	Mittagsruhe, ruhiges Spiel, Entspannung <br /><br />Freispiel oder Spiel im Freien </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>15:00  </b></p>
                <p className="m-0 col-6 border p-2 text-center"> Abfahrt der Kinder vom Kindergarten - freitags um 13:00 Uhr </p>
            </div>

            <div className="mt-4">

                <b>Verpflegung:</b>
                <p>
                    In unserer hauseigenen Küche wird ein vollwertiges, abwechslungsreiches und gesundes Mittagessen gekocht. Jedes Kind nimmt am Mittagessen teil.
                    Es werden unterschiedliche Ernährungsbedürfnisse, Lebensmittelunverträglichkeiten und Allergien berücksichtigt.
                </p>
            </div>
        </div>
    },
    {
        title: <small>Familien- und Elternangebote  </small>,
        content: <div className="p-3 text-secondary ">
            <h3>Familien- und Elternangebote  </h3>

            <p>Individuelle Aufnahmegespräche</p>
            <p>Individuelle Eingewöhnung des Kindes</p>
            <p>Förderplan- und Entwicklungsgespräche</p>
            <p>Gespräche und Hospitationen mit Therapeuten</p>
            <p>Hospitationen in der Kindergartengruppe</p>
            <p>Beratungsgespräche (Übergang in die Schule oder andere Einrichtungen, Erziehungsfragen)</p>
            <p>Elternabende, Feste und Aktionen</p>


        </div>
    },
    {
        title: <small>Aufnahme</small>,
        content: <div className="p-3 text-secondary ">
            <h3>Aufnahme</h3>
            <p>
                Eine Aufnahme im heilpädagogischen Kindergarten erfolgt nach Kontaktaufnahme und Bewilligung des Fachdienstes der Eingliederungshilfe (Bereich Kinder) des Sozialamtes beim Landkreis Schaumburg.
                <br /><br />
                Im Rahmen des Bedarfsermittlungsverfahrens Niedersachsen wird der entsprechende Förderbedarf ihres Kindes ermittelt.
                <br /><br />
                Bei Feststellung einer angezeigten Förderung im heilpädagogischen Kindergarten nehmen Sie bitte Kontakt zu uns auf.
                <br /><br />
                Gern unterstützen wir Sie bei der Antragstellung.
                <br /><br />
                Sie können unseren Kindergarten auch unverbindlich kennenlernen. Vereinbaren Sie gerne einen Termin.
            </p>

        </div>
    }

]

class HeilpädagogischeKindergarden extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0"> Heilpädagogischer Kindergarten am Waldkater</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Heilpädagogischer Kindergarten am Waldkater</h3>

                                    <p className="text-secondary">
                                        In unseren Heilpädagogischen Kindergartengruppen werden Kinder im Vorschulalter mit unterschiedlichen Entwicklungen und individuellen Bedürfnissen begleitet und gefördert.
                                        Wir unterstützen die Kinder mit einem ganzheitlichen und interdisziplinären Konzept.
                                        Kleine Gruppen mit maximal 8 Kindern eröffnen individuelle Bildungsangebote und optimale Entwicklungsbedingungen.
                                        Kinder mit und ohne Beeinträchtigung begegnen sich, spielen und lernen in unserem Haus.
                                        Ihr Kind wird von qualifizierten und kompetenten heilpädagogischen, therapeutischen und pädagogischen Fachkräften in einer vertrauensvollen Atmosphäre intensiv begleitet und gefördert
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                </>

                <div className="container">
                    <div className="col-12 bg-info text-white shadow-sm rounded text-center p-3 mt-3 ff-3">
                        <h4>„Den Kindern die Augen für die Welt zu öffnen ist unser Wunsch – sie für das Leben stark machen, ist unsere Aufgabe.“ </h4>
                        <small>(Maria Montessori)</small>
                    </div>
                </div>



                {/*  <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <h3 className="text-center">
                      
                        </h3>
                    </div>
                </>
 */}

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>



                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Sylvia Nebeling</h3>
                                            <p className="m-0 text-dark"><b>Pädagogische Leitung</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 499 </p>
                                            <p className="m-0"><Icon icon="Mail" /> hpkg@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>


                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>

            </div>);
    }
}

export default HeilpädagogischeKindergarden;