
import React from 'react';
import _ from "lodash";
import DATA from "../utils/stellenangebote.json";

// Components
import Carusel from '../components/carusel';
import Menu from '../components/menu';
import { useEffect } from 'react';


const Stellenangebote = (props) => {
    useEffect(() => window.scrollTo(0, 0), []);
    const group = Object.values(_.groupBy(DATA, item => item.gruppe));

    return <div className="animate__animated animate__fadeIn">

        <div className="container">
            {props.mobileVersion ? "" : <Menu {...props} />}
        </div>
        <>
            <Carusel bilder={require('../css/image/startCarusel')()} height="200px" width="100%" />
        </>

        <>
            <div className="container startText shadow-sm bg-white text-dark p-3  mb-3">
                <div className="row m-0">

                    <div className="col-12 ff-3 text-success">
                        <h3>Stellenangebote</h3>
                    </div>
                    {
                        group.map((gr, i) => {
                            return <div key={i} style={{ border: "3px solid " }} className={" col-12 row m-0 mt-2 rounded border-" + gr[0].color} >
                                <p className={"col-12 ff-3 text-" + gr[0].color}>{gr[0].gruppe}</p>
                                {gr.map((item, index) => {
                                    return <div key={index} className={"col-12 col-md-6 p-3 mt-3 " + item.className || ""}>
                                        <h6><b>{item.text}</b></h6>
                                        <div>
                                            <p className="m-0">Lebenshilfe Rinteln e.V.   </p>
                                            <p className="m-0">{item.contactName}</p>
                                            <p className="m-0">Waldkaterallee 13</p>
                                            <p className="m-0">31737 Rinteln</p>
                                            <p className="m-0">{item.mail}</p>
                                            <p className="m-0">{item.mobile}</p>
                                        </div>
                                    </div>
                                })}
                            </div>
                        })
                    }
                </div>

            </div>
        </>

    </div >;

}

export default Stellenangebote;