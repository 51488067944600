import React from 'react';

// Bilder
import Logo from '../css/image/logo.jpg';
import Icon from "../elements/icons";

const StellenangeboteTv2 = () => {
    return <>
        <div style={{ backgroundColor: "white", width: "100%", height: "100vh", padding: "0 2vw" }}>

            <div className="d-flex align-items-center justify-content-center pt-5">
                <img className="" src={Logo} alt="logo" width="90%" height="auto" />
            </div>


            <div className="text-center" style={{ fontSize: "3vw", marginTop: "1vh" }}>
                Das Team „der besonderen Wohnformen“ sucht dich,
                wenn du Menschen mit geistigen Beeinträchtigungen im
                stationären Bereich individuell unterstützen und fördern möchtest.
            </div>

            <div className="text-secondary text-center" style={{ fontSize: "2.5vw", marginTop: "0.8vh" }}>
                Zur Verstärkung unseres Teams suchen wir im Haus Antonia einen Wohngruppenmitarbeiter (m/w/d)
            </div>


            <div style={{ fontSize: "3.5vw", paddingLeft: "3vw", marginTop: "3vh", }}>
                <div className="text-secondary">Du bist:</div>
                <ul style={{ listStyle: "circle", paddingLeft: "7vw" }}>
                    <li>Heilerziehungspfleger/Erzieher (m/w/d)</li>
                    <li>Krankenpfleger/Altenpfleger (m/w/d)</li>
                    <li>Ergotherapeuten (m/w/d)</li>
                    <li>Sozialassistenten/Pflegeassistenten (m/w/d)</li>
                    <li>oder Quereinsteiger (m/w/d)</li>
                </ul>
            </div>

            <div className="text-secondary text-center" style={{ fontSize: "2.5vw", marginTop: "2vh", paddingLeft: "2vw" }}>
                …dann bewirb dich jetzt. Bei uns bist du richtig!
            </div>

            <div style={{ fontSize: "3.5vw", paddingLeft: "3vw", marginTop: "3vh", }}>
                <div className="text-secondary">Wir bieten dir:</div>

                <div className="d-flex" style={{ fontSize: "2.3vw", gap: "1.5vw" }}>
                    <ul style={{ listStyle: "circle", flex: 10 }}>
                        <li>Verlässliche Arbeitszeiten in Voll und Teilzeit</li>
                        <li>PC - gestützte Dokumentation </li>
                        <li>30 Tage Urlaub plus Sonderurlaubstage </li>
                        <li>Gesundheitsbonus in Form von Zusatzurlaub </li>
                        <li>Jahressonderzahlung </li>
                    </ul>
                    <ul style={{ listStyle: "circle", flex: 8 }}>
                        <li>Wochenenddienste nur alle 3 Wochen</li>
                        <li>feste Dienstpläne </li>
                        <li>Fort- und Weiterbildungen </li>
                        <li>höherer Personalschlüssel als vorgegeben</li>
                        <li>kollegiale Unterstützung im Team</li>
                    </ul>
                </div>
            </div>

            <div className="d-flex" style={{ fontSize: "3vw", marginTop: "5vh", paddingLeft: "3vw" }}>
                <div>
                    <div> <Icon icon="Phone" style={{ marginTop: "0.7vh", fontSize: "4vw", color: "gray" }} /> </div>
                    <div> <Icon icon="Mail" style={{ marginTop: "0.7vh", fontSize: "4vw", color: "gray" }} /> </div>
                    <div> <Icon icon="Send" style={{ marginTop: "0.7vh", fontSize: "4vw", color: "gray" }} /> </div>
                </div>
                <div style={{ marginLeft: "2vw" }}>
                    <div style={{ marginTop: "0.7vh" }}>05751/89104600</div>
                    <div style={{ marginTop: "0.7vh" }}>m.reinking@lebenshilfe-rinteln.de</div>
                    <div style={{ marginTop: "0.7vh" }}>Marco Reinking- <br />
                        Lebenshilfe Rinteln e.V., Wohnen <br />
                        Waldkaterallee 13-
                        31737 Rinteln
                    </div>
                </div>

            </div>

        </div>
    </>
}

export default StellenangeboteTv2;





