import React from 'react';

class Impressum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        window.scrollTo(0, 0)
        return (<>
            <div className="container bg-white p-5 animate__animated animate__bounceInDown" style={{ marginTop: "70px" }}>
                <h3>Impressum</h3>
                <table border="0" width="100%" cellspacing="0" cellpadding="0">
                    <tbody>
                        <tr>
                            <td width="100%">
                                <div align="left">
                                    <p>Lebenshilfe Rinteln e.V.</p>
                                    <p>Waldkaterallee 13</p>
                                    <p>31737 Rinteln</p>
                                    <p>Telefon: 05751 - 89 10 40</p>
                                    <p>www.lebenshilfe-rinteln.de</p>
                                    <p>Email: webmaster@lebenshilfe-rinteln.de</p>
                                    <p>&nbsp;</p>
                                    <p><strong>Eingetragen beim Amtsgericht Stadthagen auf dem Registerblatt VR 110042<br />Beim Finanzamt Stadthagen zur F&ouml;rderung mildt&auml;tiger Zwecke anerkannt unter 44/200/57067<br /></strong></p>
                                    <p>&nbsp;</p>
                                    <p><u>Vertreter des Vereins:</u></p>
                                    <p>1. Vorsitzender: Herbert Meier</p>
                                    <p>2. Vorsitzender: S&ouml;nke Lorenzen</p>
                                    <p>Gesch&auml;ftsf&uuml;hrerin Verein: Vanessa Meier</p>
                                    <p>&nbsp;</p>
                                    <p><u>Unser Copyright:</u></p>
                                    <p>Designelemente oder das Gesamt-Layout von&nbsp;<i><a className="text-secondary" href="https://lebenshifle-rinteln.de/">www.Lebenshilfe-Rinteln.de</a></i></p>
                                    <p>d&uuml;rfen nicht ohne vorherige schriftliche Einwilligung von uns kopiert oder reproduziert werden.&nbsp;&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><u>Disclaimer:</u></p>
                                    <p>Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass man durch die Ausbringung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann - so das LG - nur dadurch verhindert werden, dass man sich ausdr&uuml;cklich von diesen Inhalten distanziert.</p>
                                    <p>&nbsp;</p>
                                    <p><u>Verweise und Links:</u></p>
                                    <p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die au&szlig;erhalb des Verantwortungsbereiches des Autors liegen, w&uuml;rde eine Haftungsverpflichtung ausschlie&szlig;lich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch m&ouml;glich und zumutbar w&auml;re, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.</p>
                                    <p>&nbsp;</p>
                                    <p>Der Autor erkl&auml;rt hiermit ausdr&uuml;cklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zuk&uuml;nftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verkn&uuml;pften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdr&uuml;cklich von allen Inhalten aller gelinkten /verkn&uuml;pften Seiten, die nach der Linksetzung ver&auml;ndert wurden. Diese Feststellung gilt f&uuml;r alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise. F&uuml;r illegale, fehlerhafte oder unvollst&auml;ndige Inhalte und insbesondere f&uuml;r Sch&auml;den, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der &uuml;ber Links auf die jeweilige Ver&ouml;ffentlichung lediglich verweist.</p>
                                    <p><strong>Deshalb distanzieren wir uns hiermit ausdr&uuml;cklich von den Inhalten aller aufgef&uuml;hrten Seiten und machen uns ihre Inhalte nicht zueigen.</strong></p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>






        </>);
    }
}

export default Impressum;