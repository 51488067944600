import React from 'react';

import MaterialTab from '../components/materialTab';
import Icon from '../elements/icons';
import { Link } from "react-router-dom";
import Carusel from '../components/carusel';

import Menu from '../components/menu';
// const caruselBilder = require("../css/image/HausAmWaldkater")();
import caruselBilder from "../css/image/HausAmWaldkater/carusel";
import Bilder from "../css/image/HausAmWaldkater";
import ABild from "../css/image/ansprechpartner/nina.png";

// Downloads 
import pdf1 from '../downloads/HausAmWaldkater/Checkliste.pdf';
import pdf2 from '../downloads/HausAmWaldkater/Preisliste.pdf';
import pdf3 from '../downloads/HausAmWaldkater/Aufnahmebogen.pdf';


const tabContents = [
    {
        title: "Kurzzeitpflege",
        content: <div className="text-secondary">
            <h3 className="mt-2">Kurzzeitpflege</h3>
            <p>
                Nach einem Krankenhausaufenthalt oder bei Verhinderung der
                pflegenden Angehörigen, können wir kurzfristig einspringen.
                <br /><br />
                Gerade im Anschluss an einen Krankenhausaufenthalt ist es
                nicht leicht, sofort in den häuslichen Alltag zurück zu kehren.
                Oft ist zu Beginn noch eine intensive Pflege notwendig, die zu
                Hause nur schwer erbracht werden kann. Die Kurzzeitpflege
                bereitet einen pflegebedürftigen Menschen auf die Rückkehr in
                den eigenen Haushalt vor und entlastet dessen Angehörige.
            </p>
        </div>
    },
    {
        title: "Verhinderungspflege",
        content: <div className="text-secondary">
            <h3 className="mt-2">Verhinderungspflege</h3>
            <p>
                Pflegende Angehörige möchten eine kurze Auszeit/Urlaub oder
                sind selbst erkrankt. Auch hier kann die Kurzzeit- und
                Verhinderungspflege helfen, sich einige Tage Erholung vom
                Pflegealltag zu gönnen und neue Kraft zu tanken, mit dem
                guten Gefühl einen lieben Menschen in den besten Händen zu
                wissen.
            </p>
        </div>
    },
    {
        title: "Ärztliche Versorgung",
        content: <div className="text-secondary">
            <h3 className="mt-2">Ärztliche Versorgung</h3>
            <p>
                Jeder Bewohner, bzw. dessen Betreuer oder Angehöriger wählt den Hausarzt seines
                Vertrauens selbst. Für regelmäßige Besuche des Hausarztes sorgen und garantieren wir.
                <br /><br />
                Fachärzte wie Neurologe, Zahnarzt und Orthopäde kommen
                regelmäßig quartalsweise und bei Bedarf ins Haus und können
                in Anspruch genommen werden. Arzttermine im Haus und
                außerhalb werden von unserem Fachpersonal vereinbart.
                <br /><br />
                Das Haus am Waldkater hat mit der Post - Apotheke in Rinteln
                einen Kooperationsvertrag. Darin verpflichtet sie sich stets
                unsere Bewohner mit den erforderlichen Medikamenten
                termingerecht zu versorgen. Die Apotheke prüft auch die
                Handhabung und Lagerung aller Medikamente.
            </p>
        </div>
    },
    {
        title: "Hauswirtschaft",
        content: <div className="text-secondary">
            <h3 className="mt-2">Hauswirtschaft</h3>
            <p>
                Das Haus am Waldkater wird täglich mit zwei Mittagsgerichten beliefert, welche in der Großküche auf dem Gelände der Lebenshilfe frisch gekocht werden.
                Über einen wöchentlichen Speiseplan können die Bewohner ihr Wunschgericht im Voraus auswählen. Die Mahlzeiten werden im Speisesaal der jeweiligen Station,
                aber auch auf Wunsch im Zimmer eingenommen. Hauswirtschaftskräfte stehen von morgens bis abends zur Verfügung und bereiten die Mahlzeiten individuell für jeden Bewohner vor Ort in den Bereichsküchen zu.
                Dort gibt es auch Frühstück, Abendbrot und ein „Tässchen“ Kaffee oder Tee mit selbstgebackenem Kuchen oder anderem Gebäck. Getränke wie Kaffee, Tee, Wasser oder Säfte stehen jederzeit zur Verfügung.
                Zwischenmahlzeiten werden in Form von Obst oder Joghurt gereicht.
                In unserem Bistro im Haus am Waldkater können Bewohner mit ihrem Besuch jeden 1. Sonntag im Monat in gemütlicher Atmosphäre Kaffee und selbstgebackene Torten oder Kuchen genießen.
                Einem Plausch und Kennenlernen anderer Bewohner steht nichts im Wege.
                Hauswirtschaft heißt für uns nicht nur für das leibliche Wohl zu sorgen, sondern auch immer ein offenes Ohr zu haben und eine Schulter zum Anlehnen zu geben.
            </p>
        </div>
    },
]

class HausAmWaldkater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0"> Haus am Waldkater </h3>
                        <Carusel bilder={caruselBilder()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Leitspruch Nina Jürgens, Pflegedienstleitung </h3>
                                    <h5 className="text-success">„Es kommt nicht darauf an, dem Leben mehr Jahre zu geben, sondern den Jahren mehr Leben zu geben.“<br /> <small style={{ fontSize: "8pt" }}>Alexis Carell</small></h5>
                                    <p className="text-secondary">
                                        Das „Haus am Waldkater“ der Lebenshilfe Rinteln umfasst den Bereich der
                                        stationären Pflege mit 45 Betten auf 2 Wohnebenen.
                                        Leben im „Haus am Waldkater“ bedeutet Leben in der Gemeinschaft.
                                        Eine Gemeinschaft, in der jeder so sein darf, wie er ist. Mit seiner Geschichte,
                                        die seine Persönlichkeit geprägt hat, was wertgeschätzt und respektiert wird.
                                        <br /><br />
                                        Wir sorgen für die individuell auf jeden Bewohner angepasste Pflege. Ziel
                                        unserer Arbeit soll sein, dem pflegebedürftigen Menschen einen
                                        selbstbestimmten Alltag zu ermöglichen. Wir bieten Unterstützung an, damit
                                        die Bewohner mit der veränderten Situation zurechtkommen.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 p-0 pl-2 rounded">
                                <img className="shadow-sm rounded" src={Bilder()[0]} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />
                            </div>

                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <p>
                            Täglich wird frisch gekochtes Mittagessen durch unsere hauseigene Küche serviert.
                            Frühstück und Abendbrot werden durch die Hauswirtschaftskräfte nach den Wünschen der einzelnen Bewohner zubereitet.
                            Zur Kaffeezeit können Bewohner in einer entspannten Atmosphäre die oft selbst gebackenen Kuchen und Torten, sowie Kaffee – und Teespezialitäten genießen.
                            <br /><br />
                            Die Zimmer können mit eigenen Möbeln und nach eigenem Geschmack eingerichtet werden.
                            Wir möchten, dass sich jeder Bewohner heimisch fühlen kann. Angehörige und Freunde sind
                            wichtige Bezugspersonen. Wir bieten Ihnen einen vertrauensvollen Kontakt an und beziehen
                            Sie in die Pflege – und Betreuungsplanung mit ein.
                        </p>

                    </div>
                </>

                <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-3 text-white">
                        <p>
                            Die pflegerische Arbeit soll sich nicht nur an dem individuellen Grad der Erkrankung und der Bewohnerdefizite orientieren, sondern aus der Bedeutung der Biografie des Einzelnen verstanden werden und in die Betreuung mit einfließen.
                            Soziale Kontakte der Bewohner innerhalb und außerhalb der Einrichtung fördern wir, respektieren aber ebenso ihre Wünsche nach Alleinsein.
                        </p>
                    </div>
                </>

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Nina Jürgens</h3>
                                            <p className="m-0 text-dark"><b>Pflegedienstleitung </b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751- 89 104 700</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751- 89 104 629</p>
                                            <p className="m-0"><Icon icon="Mail" /> <small>n.juergens@lebenshilfe-rinteln.de</small></p>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">
                                        <a className="col-12 mt-3 d-flex" href={pdf1} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Checkliste bei Aufnahme</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={pdf2} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Preisliste</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={pdf3} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Aufnahmebogen</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            </div>);
    }
}

export default HausAmWaldkater;