import React from 'react';
import { Link } from "react-router-dom";
// import MaterialTab from '../components/materialTab';

import Carusel from '../components/carusel';
import Icon from '../elements/icons';
// import Gallerie from '../components/gallerie';

import Menu from '../components/menu';

import ABild from "../css/image/ansprechpartner/Dirk.png";
import Bild from '../css/image/antonia/1.JPG';
let CaruselBilder = require('../css/image/antonia/carusel');
CaruselBilder = CaruselBilder();


/* const tabContents = [
    {
        title: "Unsere Bewohnervertretung",
        content: <div className="mt-1 text-secondary">
            <h4>Unsere Bewohnervertretung</h4>
            <div className="d-flex">

                <p>
                    Besteht aus 5 Bewohnern – diese sind Ansprechpartner für alle Belange der Bewohner wie Anregungen oder Beschwerden.
                    Sie trifft sich regelmäßig und nimmt an überregionalen Treffen teil. Eine Mitarbeiterin (Assistentin der BV) unterstützt sie dabei.
                </p>


            </div>
        </div>
    },
    {
        title: "Unsere interne Tagesstruktur",
        content: <div className="mt-1 text-secondary">
            <h4>Unsere interne Tagesstruktur</h4>
            <div className="d-flex">

                <p>
                    Für Bewohner, die das Rentenalter erreicht haben oder aus gesundheitlichen Gründen nicht mehr arbeiten können bieten wir eine Tagesstruktur an.
                    Vielfältige Beschäftigungsangebote orientieren sich an den Wünschen und Interessen der Bewohner und beziehen Freizeitangebote im näheren Umfeld mit ein.
                </p>


            </div>
        </div>
    }
] */
class Antonia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        window.scrollTo(0, 0);
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Antonia</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Antonia</h3>
                                    {/*  <h5 className="text-success ff-3">Kein text</h5> */}
                                    <p className="text-secondary">
                                        Das Haus Antonia ist frisch saniert und wurde im Februar 2021 bezogen. Es bietet
                                        Platz für 31 Bewohner auf zwei Etagen und ist durch eine Fahrstuhlanlage
                                        barrierefrei. Es ist ein Bereich für jüngere und ältere Bewohner, die nicht mehr am
                                        Arbeitsleben in der Werkstatt für Menschen mit Beeinträchtigung teilhaben können. Die
                                        gemischte Altersstruktur ist eine Bereicherung für alle.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />

                            {/*  <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>„Miteinander Füreinander“</h4>
                            </div> */}


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    Große helle Wohnküchen mit gemütlichen Sitzecken ermöglichen gemeinsame
                                    Mahlzeiten und Entspannungszeit gleichermaßen.
                                    Das lichtdurchflutete Dachgeschoss bietet mit Multifunktionsräumen ausreichend
                                    Platz für die Angebote der internen Tagesstruktur, Fitnessgruppen, Musikrunden etc.
                                    Durch eine Küche ist auch hier gemeinsames Backen und Werkeln möglich.
                                    Ein großer Außenbereich mit Pflasterflächen, Beeten und Grillplatz ist Treffpunkt und
                                    Entspannungsort.
                                </p>
                            </div>

                        </div>
                    </div>
                </>



                {/*      <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div>
                </> */}

                {/*    <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </> */}

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="m-0 text-dark">Dirk Aechter</h3>
                                            <p className="m-0"><Icon icon="Phone" /> 05751- 89 104 660</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751- 89 104 629 </p>
                                            <p className="m-0"><Icon icon="Mail" /> <small>d.aechter@lebenshilfe-rinteln.de</small></p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>

            </div>
        </>);
    }
}

export default Antonia;