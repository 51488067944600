import React from 'react';
import { NavLink, Link } from "react-router-dom";

// Material
import { AppBar, Toolbar, Button, /* Menu, MenuItem, */ Drawer, List, ListItem, ListItemText, ListItemIcon, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


// Elements
import Icon from '../elements/icons';
import MobileMenu from '../elements/mobileMenu';

// Bilder
import Logo from '../css/image/logo.jpg'

const materialStyle = {
    AppBar: {
    },
    Drawer: {
        maxWidth: "280px",
        minWidth: "280px"
    }
};

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            currentTarget: "",
            drawerOpen: false
        }
    }
    setNavState(state) {
        this.setState(state);
    }


    handleMenu(e) {
        this.setState({ menuOpen: !this.state.menuOpen, anchorEl: e.currentTarget });
    }


    render() {
        return (<>
            <AppBar position="fixed" color="inherit" className={/* this.props.classes.AppBar */ "shadow-sm"} >
                <Toolbar >
                    <>
                        {
                            this.props.mobileVersion ?
                                <Link to="/" ><img className="ml-1 pointer" src={Logo} alt="logo" width="200px" height="auto" /></Link>
                                :
                                <>
                                    <Link to="/" ><img className="ml-1 pointer" src={Logo} alt="logo" height="70" /></Link>
                                    <Button onClick={() => window.open("tel:05751891040", "_self")} className="text-info p-3 mt-1 ml-auto"><b><Icon icon="PhoneForwarded" /> 05751- 89 104 0</b> </Button>
                                    <NavLink activeClassName="active" to="/kontakt" onClick={() => this.setState({ drawerOpen: false })}>
                                        <Button className="text-info ml-2 p-3 mt-1"  ><Icon icon="ImUser" /> &nbsp;&nbsp;<b>Kontakt</b></Button>
                                    </NavLink>
                                </>
                        }
                    </>
                    <Button onClick={() => this.setState({ drawerOpen: !this.state.drawerOpen })} className={this.props.mobileVersion ? "ml-auto text-dark" : "text-info"} >Menu <Icon icon="Menu" /></Button>
                </Toolbar>
            </ AppBar >

            <Drawer
                anchor="right"
                classes={{ paper: this.props.classes.Drawer }}
                onClose={() => this.setState({ drawerOpen: false })}
                open={this.state.drawerOpen}
            >

                <Link to="/" ><img className="ml-1 pointer" src={Logo} alt="logo" width="90%" height="auto" /></Link>

                <div className="mt-3">
                    {this.props.config.startMenu.map((item, i) => <MobileMenu setNavState={this.setNavState.bind(this)} item={item} key={i} />)}
                </div>
                <List className="text-info">
                    <ListItem button onClick={() => window.open("tel:05751891040", "_self")}>
                        <ListItemIcon><Icon icon="PhoneForwarded" style={{ color: "#006eb3" }} /></ListItemIcon>
                        <ListItemText  ><span className="ff-2">05751- 89 104 0</span></ListItemText>
                    </ListItem>

                    <Link to="/kontakt" className="d-flex">
                        <ListItem button onClick={() => this.setState({ drawerOpen: false })}>
                            <ListItemIcon><Icon icon="ImUser" style={{ color: "#006eb3" }} /></ListItemIcon>
                            <ListItemText  ><span className="ff-2">Kontakt</span></ListItemText>
                        </ListItem>
                    </Link>

                </List>
            </Drawer>
        </>
        );
    }
}

export default withStyles(materialStyle)(Nav);

