import React from 'react';
import Carusel from '../components/carusel';
import Menu from '../components/menu';

import Bild from '../css/image/bmh/1.jpg';
let CaruselBilder = require('../css/image/bmh/carusel');
CaruselBilder = CaruselBilder();




class BMH extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }






    render() {
        window.scrollTo(0, 0);
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">British Military Hospital</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">British Military Hospital</h3>
                                    {/*    <h5 className="text-success ff-3"> Text fehlt</h5> */}
                                    <p className="text-secondary">
                                        Geschichtliches der Einrichtung, von der Planung als Kaserne, zur Übernahme als Krankenhaus und der
                                        Verwendung als BMH(British Military Hospital).
                                        Zu Beginn der 30er Jahre des letzten Jahrhunderts bekam die Reichswehr den Auftrag eine Kaserne im
                                        Bereich Rinteln zu planen und zu errichten.
                                        Diese Pläne waren zu Beginn des zweiten Weltkrieges fortgeschritten, Teile der Kaserne errichtet und die
                                        Abschlussarbeiten waren geplant.
                                        Durch den Verlauf des Krieges mussten diese Pläne geändert werden und die Umbauten zu einem
                                        Krankenhaus, für die vom Bombenkrieg beeinträchtigte Zivilbevölkerung, begannen.
                                        1942 waren die ersten Verletzten aus Hannover und Umgebung aufgenommen und das Krankenhaus 1944
                                        fertig gestellt und in Betrieb.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />

                            {/*    <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4> Text fehlt</h4>
                            </div> */}


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary mb-3">
                                <p className="pt-2">
                                    Nach Ende des Krieges übernahm die britische Armee das Hospital und nannte es in BMH Rinteln um.
                                    In diesem Krankenhaus sollen bis 1997 ca. 50.000 Babies geboren worden sein und es hatte innerhalb der
                                    britischen Armee einen außergewöhnlich guten Ruf.
                                    Einzigartig war auch, dass es das einzige BMH war, das von einem Brigadier General (Brigadegeneral)
                                    kommandiert wurde.
                                    Seit 2017 besteht ein lockerer aber herzlicher Kontakt zu ehemaligen Soldatinnen und Soldaten die hier
                                    stationiert waren.
                                </p>
                                <p>
                                    Es kommen immer wieder Besucher aus allen Teilen der Welt, die sehen möchten, wo sie selbst oder ihre Angehörigen geboren wurden.
                                </p>
                            </div>

                        </div>
                    </div>
                </>


                {/*    <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                </> */}
                {/*  <div className="container mt-3">
                    <Gallerie photos={photos} />
                </div> */}

                {/*  <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </> */}


            </div>
        </>);
    }
}

export default BMH;