import React from 'react';
import { Link } from "react-router-dom";
import Icon from './icons';

// Material 
import { List, ListItem, ListItemText, Collapse, ListItemIcon, } from '@material-ui/core';

class ListCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false }
    }

    handleClick() {
        this.setState({ open: !this.state.open });
    }
    render() {
        return (<>
            <ListItem button onClick={() => this.handleClick()}>
                {this.props.icon ? <ListItemIcon><Icon icon={this.props.icon} /></ListItemIcon> : ""}
                <ListItemText primary={this.props.text} />
                {this.state.open ? <Icon icon="ExpandLess" /> : <Icon icon="ExpandMore" />}
            </ListItem>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {this.props.props.map((e, i) => {
                        return <Link to={e.path} key={i}>
                            <ListItem button className="text-secondary">
                                {e.icon ? <ListItemIcon><Icon icon={e.icon} /></ListItemIcon> : ""}
                                <ListItemText primary={e.text} />
                            </ListItem>
                        </Link>
                    })}
                </List>
            </Collapse>
        </>);
    }
}
export default ListCollapse;