import React from 'react';
import { Link } from "react-router-dom";

import MaterialTab from '../components/materialTab';
import Icon from '../elements/icons';
import Menu from '../components/menu';

import ABild from "../css/image/ansprechpartner/sylvia.png";
import ABild2 from "../css/image/ansprechpartner/claudia.png";

import Carusel from '../components/carusel';

import caruselBilder from "../css/image/krippe/carusel";
import Bild from "../css/image/krippe/1.JPG";



const tabContents = [

    {
        title: "Betreuungsangebot ",
        content: <div className="p-3 text-secondary ">
            <h3>Betreuungsangebot </h3>
            <div className="row m-0">
                <div className="col-12 col-md-6">

                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Integrative Kita am Waldkater</b></p>
                        <p className="m-0 col-6 border p-1 text-center"><b>1 Krippengruppe</b></p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Betreuungsalter der Kinder</b></p>
                        <p className="m-0 col-6 border p-1 text-center">1 – 3 Jahre</p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Anzahl der Plätze</b></p>
                        <p className="m-0 col-6 border p-1 text-center">12 – 15 Kinder <br />(je nach Alters-zusammensetzung)</p>
                    </div>
                    <div className="row m-0 ">
                        <p className="m-0 col-6 border p-1 text-center"><b>Betreuungszeiten</b></p>
                        <p className="m-0 col-6 border p-1 text-center">
                            8:00 – 12:30<br />
                            8:00 – 14:00<br />
                            8:00 – 16:00<br /><br />
                            Sonderöffnungszeit für berufstätige Eltern:<br />
                            7:30 – 8:00  <br />
                            16:00 – 16:30
                        </p>
                    </div>

                </div>



                <div className="col-12 col-md-6">
                    <p>
                        Der Einzugsbereich für die integrative KiTa am Waldkater ist die Stadt Rinteln mit den umliegenden Ortsteilen.<br /><br />
                        Die Krippengebühren richten sich nach der Satzung der Stadt Rinteln. Sie staffeln sich entsprechend des Betreuungsumfanges und des Einkommens der Familie.<br /><br />
                        Der Mittagessenbeitrag beträgt zurzeit 64,00 €. Unter bestimmten Voraussetzungen kann eine Übernahme des Beitrages durch BuT beantragt werden.<br /><br />
                        In der Regel schließen wir 3 Wochen in den niedersächsischen Sommerferien sowie zwischen Weihnachten und Neujahr. Weitere Schließzeiten für Brückentage oder Teamtage werden, wenn möglich langfristig vorher bekanntgegeben.
                    </p>
                </div>


            </div>

        </div>
    },



    {
        title: "Unsere pädagogische Arbeit  ",
        content: <div className="p-3 text-secondary ">
            <h3>Unsere pädagogische Arbeit  </h3>
            <div className="row m-0 ">

                <div className="col-12 col-md-6 mt-2">
                    <p><b>Eingewöhnung:</b></p>
                    <p>Der Eintritt in eine Tagesbetreuung verlangt von den Kindern erhebliche Anpassungsleistungen an die neue Umgebung, die für Kinder mit großen Anstrengungen verbunden sind. Damit stellt die Eingewöhnungsphase einen Schlüsselprozess dar und erhält eine besondere Bedeutung in der pädagogischen Arbeit. Alle Kinder sollten zu Beginn des Besuchs einer Kindertageseinrichtung während einer Eingewöhnungszeit von einem Elternteil (oder einer anderen Bindungsperson) begleitet werden. Die Bindungsperson steht dem Kind sicher und unterstützend zur Seite. Wichtig ist, dass das Kind spürt, dass sich auch die Bezugsperson in unserer Einrichtung wohl fühlt. Nur so kann sich das Kind auf die neue Situation einlassen und sich in der Kindertagestätte sicher und geborgen fühlen. Der Eingewöhnungsprozess orientiert sich am Kind und jedes Kind bekommt die Zeit, die es braucht. Dies geschieht in drei Phasen, bei denen die Eltern eng mit einbezogen werden.  </p>
                    <ul>
                        <li>Grundphase:<br />Das Kind wird von einer Bezugsperson kontinuierlich begleitet und kann so die neuen Räumlichkeiten und Personen (Kinder, Fachkräfte) kennenlernen.</li>
                        <li>Stabilisierungsphase (Trennungsphase):<br />Sobald der Bezug zu der pädagogischen Fachkraft besteht und das Kind sich von der Bezugsperson löst, können erste Trennungsversuche stattfinden</li>
                        <li>Schlussphase:<br />Die Eingewöhnung ist abgeschlossen, sobald sich das Kind von den pädagogischen Fachkräften trösten, wickeln und schlafen legen lässt. Die Eltern sind weiterhin auf Abruf, falls das Kind einen Rückschritt macht.</li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 mt-2">
                    <p><b>Unsere pädagogische Arbeit:</b></p>
                    <p>
                        Unsere pädagogische Arbeit mit Kindern unter 3 Jahren orientiert sich den Handlungsempfehlungen zum niedersächsischen Bildungs- und Orientierungsplan. Folgende Lernbereiche werden dazu aufgegriffen:
                    </p>
                    <ul>
                        <li>Emotionale Entwicklung und soziales Lernen</li>
                        <li>Entwicklung kognitiver Fähigkeiten und die Freude am Lernen</li>
                        <li>Körper, Bewegung und Gesundheit</li>
                        <li>Sprache und Sprechen</li>
                        <li>Lebenspraktische Kompetenzen</li>
                        <li>Mathematisches Grundverständnis</li>
                        <li>Ästhetische Bildung</li>
                        <li>Natur und Umwelt</li>
                        <li>Ethische (und religiöse) Fragen / Grunderfahrungen menschlicher Existenz </li>
                    </ul>
                    <p>Beobachtungen und Dokumentationen sind ein Instrument unserer pädagogischen Arbeit und bieten die Grundlage zum Austausch mit den Eltern über die Entwicklung ihres Kindes.</p>
                </div>
                <div className="col-12 col-md-6 mt-2">
                    <p><b>Unsere Kita ist ein Ort, wo Kinder</b></p>
                    <ul>
                        <li>mit Spaß und Freude spielend lernen können</li>
                        <li>ihre Kreativität ausleben können</li>
                        <li>Impulse für anregende Lern- und Entwicklungsprozesse finden</li>
                        <li>lebensbedeutsame Erfahrungen sammeln können</li>
                        <li>ein Mitspracherecht haben und gehört werden, da unser pädagogisches Personal immer ein offenes Ohr für die Bedürfnisse und Belange der Kinder hat</li>
                        <li>stark gemacht werden für ihren Lebensweg</li>
                        <li>beziehungsfähig werden und sich als Teil einer Gruppe anerkennen mit ihren persönlichen und individuellen Bedürfnissen</li>
                        <li>eine sichere, liebevolle und zugewandte Begleitung erfahren in einem konstruktiven und freundlichen Miteinander</li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 mt-2">
                    <p><b>Das Team:</b></p>
                    <i>Pädagogisches Team </i>
                    <p className="text-info">Pädagogische Leitung und Stellvertretung</p>
                    <div className="row m-0">

                        <p><ins>Krippe:</ins></p><br />
                        Staatlich anerkannte Krippenerzieher/-innen<br />
                        Staatlich anerkannte Erzieher/-innen<br />
                        Staatlich anerkannte Sozialassistenten/-innen

                    </div>
                </div>
            </div>
        </div>
    },
    {
        title: "Raumangebote ",
        content: <div className="p-3 text-secondary ">
            <h3>Raumangebote </h3>
            <p>
                Die Mäusegruppe hat ihre Räumlichkeiten im Erdgeschoss des Haupthauses der Kita am Waldkater, in dem ebenfalls die Integrationsgruppe und der Heilpädagogische Kindergarten untergebracht sind.
            </p>
            <p>Zu den Räumlichkeiten gehören:</p>

            <ul>
                <li>ein großer, spielanregender, krippengerechter Gruppenraum mit Küchenzeile</li>
                <li>ein Waschraum mit einem großen Wickeltisch, Toiletten/ Mini WC und Waschtisch mit der Möglichkeit zum Experimentieren mit Wasser sowie eine Duschmöglichkeit </li>
                <li>zwei Ruheräume </li>
                <li>Garderobenbereich </li>
                <li>Mitarbeiterraum</li>
            </ul>
            <p>
                Im Außenbereich befindet sich ein separater Spielbereich für die Krippenkinder. Zusätzlich kann das anregungsreiche und bewegungsfreundliche,
                großzügige Außengelände mit über 120.000 m² für Bewegungsangebote und Spaziergänge genutzt werden.<br />
                Es stehen folgende gruppenübergreifende Raumangebote zur Verfügung:
            </p>

            <ul>
                <li>eine Psychomotorik – Halle für sportliche Angebote</li>
                <li>ein Pavillon für musisch – rhythmische Bewegungsangebote</li>
            </ul>
        </div>
    },
    {
        title: "Tagesablauf  ",
        content: <div className="p-3 text-secondary ">
            <h3>Tagesablauf  </h3>
            <p>
                Ein strukturierter Rahmen von Verlässlichkeit und Routine bietet Kindern Sicherheit und Beständigkeit und gibt ihnen eine Orientierung im pädagogischen Alltag. Im gesamten Vormittagsbereich und nach dem Mittagessen bleibt viel Zeit für das freie Spiel sowie für didaktisch- methodisch angeleitete Angebote. Im Spiel erobern sich Kinder aktiv ihre Welt und erweitern ihre Fähigkeiten und Kompetenzen stetig.
                Mindestens einmal pro Woche stehen jeder Gruppe die Psychomotorik - Halle und der Pavillon für Bewegungsangebote zur Verfügung. Außerdem bietet das große Außengelände viel Raum und Platz für verschiedene Möglichkeiten zur täglichen Bewegung für alle Gruppen
            </p>

            <div className="row m-0 mt-3">
                <p className="m-0 col-6 border p-2 text-center"><b>Uhrzeit</b></p>
                <p className="m-0 col-6 border p-2 text-center"><b><ins>Tagesablauf Krippe </ins></b></p>
            </div>

            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>08:00 – 08:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Bringzeit der Kinder  </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>08:30-08:45</b></p>
                <p className="m-0 col-6 border p-2 text-center">Morgenkreis (Begrüßung/Anwesenheit/Angebote am Tag mit den Kindern besprechen)</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>08:45<br />anschließend </b> </p>
                <p className="m-0 col-6 border p-2 text-center">Frühstück <br />Freispiel  <br />Spielen/Malen/Singen <br /> <br /> (kreative, musische, motorische, sprachliche und soziale Angebote drinnen und draußen)</p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 11:30</b></p>
                <p className="m-0 col-6 border p-2 text-center">Mittagessen<br /> Abholphase für Mittagskinder (die keinen Mittagsschlaf machen) </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 12:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Mittagsschlaf  </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>ab 14:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Aufwachen und Imbiss / Abholen </p>
            </div>
            <div className="row m-0">
                <p className="m-0 col-6 border p-2 text-center"><b>15:00</b></p>
                <p className="m-0 col-6 border p-2 text-center">Freispiel bis zum Abholen</p>
            </div>

            <div className="mt-4">

                <b>Verpflegung:</b>
                <p>
                    Die Kinder bringen ihr eigenes Frühstück mit und frühstücken gemeinsam in der ganzen Gruppe. Tee und Wasser stehen dabei frei zur Verfügung.
                    <br /> <br />
                    In der hauseigenen Küche wird das Mittagessen für die gesamte Einrichtung gekocht, dabei werden familiäre und religiöse Hintergründe berücksichtigt. Zum gemeinsamen Mittagessen bekommen die Gruppen regelmäßig zusätzlich frisches Gemüse und Obst.
                    <br /> <br />
                    Am Nachmittag haben die Kinder die Möglichkeit für eine Snackpause mit eigenen mitgebrachten Speisen, dazu werden Getränke sowie evtl. Obst und Gemüse gereicht
                </p>
            </div>
        </div>
    },
    {
        title: "Familien- und Elternangebote  ",
        content: <div className="p-3 text-secondary ">
            <h3>Familien- und Elternangebote  </h3>
            <div className="row m-0">
                <div className="col-12">
                    <b>Zusammenarbeit mit Familien </b>
                    <p>
                        Eine Zusammenarbeit der pädagogischen Fachkräfte mit den Eltern ist wichtig, um zum Wohl der Kinder zu handeln und gute Entwicklungsbedingungen zu schaffen. Der Dialog zwischen Eltern und pädagogischen Fachkräften dient zum Austausch von Gedanken, Informationen, Gefühlen, Erfahrungen, Ansichten, Ideen und Fragen und ist der Grundpfeiler für eine vertrauensvolle Zusammenarbeit.
                        Kinder fühlen sich wohl und zeigen Entwicklungsschritte (ein Explorationsverhalten), wenn zwischen Eltern, päd. Fachkräften und Träger eine tragfähige und partnerschaftliche Beziehung besteht, geprägt durch das gemeinschaftliche Handeln.
                    </p>
                </div>
                <div className="col-md-6">
                    <b> Möglichkeiten der Zusammenarbeit mit Familien:</b>
                    <ul>
                        <li>Aufnahmegespräche</li>
                        <li>Individuelle Absprachen zur Eingewöhnung</li>
                        <li>Elterngespräche – Entwicklungsgespräche</li>
                        <li>Elternrat</li>
                        <li>Beratungsgespräche</li>
                        <li>Eltern-Kind-Aktionen und Feste </li>
                        <li>Elternabende</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <b>Zusammenarbeit mit anderen Institutionen:</b>
                    <ul>
                        <li>Einrichtungen auf dem Gelände der Lebenshilfe Rinteln e.V.</li>
                        <li>Frühförderung</li>
                        <li>Kindergartengruppen</li>
                        <li>Familienhilfe</li>
                    </ul>
                </div>

            </div>



        </div>
    },
    {
        title: "Anmeldungen/ Aufnahme",
        content: <div className="p-3 text-secondary ">
            <h3>Anmeldungen/ Aufnahme</h3>
            Anmeldungen nehmen wir im gesamtem Jahr entgegen. <Link to="/kontakt"><b className="text-info"><i>Kontakt</i></b></Link><br /><br />
            Die Aufnahme des Kindes erfolgt in der Regel zu Beginn des neuen Kindergartenjahres.
            Sofern freie Plätze zur Verfügung stehen, können Kinder auch im laufenden Jahr aufgenommen werden.<br /><br />
            Wenn sie Fragen zur Anmeldung bzw. Aufnahme haben oder wenn sie sich über unsere Einrichtung in einem persönlichen Gespräch informieren möchten, können sie gerne telefonisch einen Termin vereinbaren.
        </div>
    }

]

class Krippe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0"> Krippe</h3>
                        <Carusel bilder={caruselBilder()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Integrative Kindertagesstätte am Waldkater</h3>

                                    <p className="text-secondary">
                                        Herzlich Willkommen bei der integrativen Kindertagesstätte am Waldkater.
                                        Nach langjähriger Erfahrung im Bereich der heilpädagogischen Arbeit im Kindergartenalter wurde am 01.09.2016 die erste Kindergartengruppe mit 25 Regelplätzen auf dem Gelände der Lebenshilfe Rinteln e.V. eröffnet.
                                        Diese wurde später mit einer Kleingruppe mit bis zu 10 Kindern erweitert.
                                        Im September 2019 kamen eine Krippengruppe mit 15 Plätzen und eine Integrationsgruppe mit 18 Plätzen dazu. So wurde aus dem Kindergarten die
                                        <b> integrative Kindertagesstätte am Waldkater</b>.<br />
                                        Im Frühjahr 2022 eröffnen wir eine weitere Krippengruppe mit ca. 15 Plätzen.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="auto" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <h3 className="text-center">
                            „Wenn man genügend spielt, solange man klein ist,
                            trägt man Schätze mit sich,
                            aus denen man ein Leben lang schöpfen kann.“<br /><br />
                            (Astrid Lindgren)
                        </h3>
                    </div>
                </>


                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>






                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Sylvia Nebeling</h3>
                                            <p className="m-0 text-dark"><b>Pädagogische Leitung</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 499 </p>
                                            <p className="m-0"><Icon icon="Mail" /> hpkg@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <hr />
                                    </div>

                                    <div className="col-12 col-md-5 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild2} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Claudia Schrader</h3>
                                            <p className="m-0 text-dark"><b>(stellv. Pädagogische Leitung)</b></p>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 400</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 499 </p>
                                            <p className="m-0"><Icon icon="Mail" /> kita@lebenshilfe-rinteln.de</p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">


                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/krippe/Voranmeldung Krippe.pdf')} download>
                                            <Icon icon="FaFilePdf" />  <span className="ml-2">Voranmeldung Krippe</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        {/*    <a className="col-12 mt-3 d-flex" href={require('../downloads/Text.txt')} download>
                                            <Icon icon="FaFileAlt" />  <span className="ml-2">Das ist ein test Datei</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/bild.jpg')} download>
                                            <Icon icon="Image" />  <span className="ml-2">Das ist ein test Bild</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>);
    }
}

export default Krippe;