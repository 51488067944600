import React from 'react';

// Bilder
import Logo from '../css/image/logo.jpg';
import { Divider } from '@material-ui/core';
import qrCode from "../css/image/qrcode.png";

const StellenangeboteTv = () => {
    return <>
        <div style={{ backgroundColor: "white", width: "100%", height: "100vh", fontSize: "5.5vw", padding: "0 3vw" }}>

            <div className="d-flex align-items-center justify-content-center pt-5">
                <img className="" src={Logo} alt="logo" width="90%" height="auto" />
            </div>

            <div style={{ marginTop: "10px" }}>
                <span style={{ color: "#565656" }}>Wir stellen ein...</span>
            </div>

            <div className="d-flex flex-column ff-3 __text-warning" style={{ color: "#673ab7", fontSize: "3.4vw", marginTop: "3vh" }}>
                <p>Therapiezentrum am Waldkater</p>
                <Divider className="__bg-warning" style={{ backgroundColor: "#673ab7", width: "70%", height: "10px" }} />
                <p className="ff-2 text-dark"><b>Physiotherapeuten / Ergotherapeuten / Logopäden</b></p>
            </div>

            <div className="d-flex flex-column ff-3 text-warning" style={{ fontSize: "3.4vw", marginTop: "3vh" }}>
                <p>Integrative Kindertagesstätte / Heilpädagogischer Kindergarten </p>
                <Divider className="bg-warning" style={{ width: "70%", height: "10px" }} />
                <p className="ff-2 text-dark"><b>Erzieher / Heilpädagogen / Heilerziehungspfleger</b></p>
            </div>

            <div className="d-flex flex-column ff-3 text-info" style={{ fontSize: "3.4vw", marginTop: "3vh" }}>
                <p>Wohnen</p>
                <Divider className="bg-info" style={{ width: "20%", height: "10px" }} />
                <p className="ff-2 text-dark"> <b>Heilerziehungspfleger / Erzieher / Ergotherapeuten / Kranken- oder Altenpfleger</b> </p>
            </div>

            <div className="d-flex flex-column ff-3 text-danger" style={{ fontSize: "3.4vw", marginTop: "3vh" }}>
                <p>Assistenz beim Wohnen</p>
                <Divider className="bg-danger" style={{ width: "52%", height: "10px" }} />
                <p className="ff-2 text-dark"><b>Heilpädagogen / Sozialpädagogen</b></p>
            </div>

            <div className="d-flex flex-column ff-3 text-success" style={{ fontSize: "3.4vw", marginTop: "3vh" }}>
                <p>Haus am Waldkater</p>
                <Divider className="bg-success" style={{ width: "45%", height: "10px" }} />
                <p className="ff-2 text-dark"><b>Pflegefachkräfte / Pflegehelfer / Hauswirtschaftskräfte</b></p>
            </div>

            <div className="d-flex ff-2 text-gray" style={{ fontSize: "4vw", marginTop: "3vh", }}>
                <img src={qrCode} style={{ width: "30vw" }} height="auto" alt='qrCode' className='border' />
                <div className="text-dark d-flex ff-1" style={{ flex: 1, }}>
                    <span className="m-auto text-center" style={{ lineHeight: "150px" }}>
                        <span className="ff-3">Besuchen Sie uns auf</span> <br />
                        <u className="text-info ff-2">www.lebenshilfe-rinteln.de</u>
                    </span>
                </div>
            </div>
        </div>
    </>
}

export default StellenangeboteTv;