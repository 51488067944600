import React from 'react';

import MaterialTab from '../components/materialTab';
import Icon from '../elements/icons';
import { Link } from "react-router-dom";
import Carusel from '../components/carusel';
import Menu from '../components/menu';
import Bild from '../css/image/früforderung/1.jpg';
import ABild from "../css/image/ansprechpartner/Ulrike.png";
let CaruselBilder = require('../css/image/früforderung/carusel');
CaruselBilder = CaruselBilder();


const tabContents = [
    {
        title: "Was ist Frühförderung?",
        content: <div className="text-secondary">
            <h3 className="mt-2">Was ist Frühförderung?</h3>
            <p>
                Frühförderung ist eine Maßnahme aus dem Bereich der Heilpädagogik.
            </p>
            <p>
                Frühförderung kann Kinder in ihrem Sein begleiten, unterstützen, stärken und ermutigen.
                Denn Kinder wollen: groß werden, gemeinsam spielen und lernen, Aufgaben meistern und
                Spaß haben.
            </p>
            <p>
                Ziel ist es, Kinder so zu begleiten und zu unterstützen, dass sie im bestmöglichen Umfang am
                gesellschaftlichen Leben teilhaben können und eine Chance auf eine umfangreiche
                Entwicklung erhalten.
                Frühförderung arbeitet ganzheitlich und vereint die Fähigkeiten und Förderbedarfe in allen
                Bereichen der Entwicklung.
            </p>
        </div>
    },
    {
        title: "Unsere Arbeitsweise",
        content: <div className="text-secondary">
            <h3 className="mt-2">Unsere Arbeitsweise</h3>
            <p>
                Wir bieten eine individuelle, ganzheitliche, auf den Entwicklungsstand Ihres / des Kindes
                abgestimmte Förderung, d.h.
            </p>
            <ul>
                <li>Arbeit nach dem Heilpädagogischen Ansatz</li>
                <li>eine an Bewegung und Sprache orientierte spielerische Förderung</li>
                <li>Elemente aus der Heilpädagogik, Psychomotorik, Rhythmik, Musik und dem kreativen Gestalten</li>
                <li>Unterstützung im sozialen und emotionalen Bereich</li>
                <li>Erfahrungen und Informationen über weiterführende Einrichtungen und Therapiemöglichkeiten</li>
                <li>Marte Meo</li>
            </ul>
            <p>
                Wir beginnen die Förderung bei den Kindern zu Hause und bringen entwicklungsgerechtes
                Spiel- und Fördermaterial mit. Zusätzlich haben wir ideale Bedingungen in unseren
                Frühförderräumen, um weitere motivierende Entwicklungsanreize mit verschiedenen
                Materialien zu geben. Uns ist eine enge Zusammenarbeit, mit Ihnen/ den Eltern besonders
                wichtig. Denn Sie wissen am besten um die Vorlieben, Bedürfnisse, Stärken und
                Abneigungen Ihres Kindes. Wir können Ihnen fachlich qualifizierte Begleiter sein, die Sie bei
                der Förderung und Erziehung Ihres Kindes unterstützen und beraten.
            </p>

        </div>
    },
    {
        title: "Zielgruppe und Aufnahme",
        content: <div className="text-secondary">
            <h3 className="mt-2">Zielgruppe und Aufnahme</h3>
            <p>
                Frühförderung ist eine Anlaufstelle für Familien und begleitet Kinder von der Geburt bis zum
                Schuleintritt.
            </p>
            <p>
                Wir unterstützen Familien, deren Kinder in ihrer körperlichen, geistigen, emotionalen,
                sozialen oder sprachlichen Entwicklung eine individuelle Förderung benötigen.
            </p>
            <p>
                Wenden Sie sich gerne an uns, wenn Ihnen auffällt, dass Ihr Kind
            </p>
            <ul>
                <li>keine Fortschritte in der Entwicklung macht oder Sie das Gefühl haben, dass etwas nicht stimmt </li>
                <li>wenig oder gar nicht spricht oder es kaum zu verstehen ist</li>
                <li>ohne erkennbaren Grund viel schreit und schwer zu beruhigen ist</li>
                <li>besonders ruhig, in sich gekehrt oder auffällig aktiv und unruhig ist</li>
                <li>sich nicht altersentsprechend konzentrieren kann und schnell abgelenkt ist</li>
                <li>keinen Schritt ohne Sie machen kann/ möchte oder jedem Menschen gegenüber zu offen ist </li>
                <li>sich in der Kitagruppe nicht zurecht findet oder Sie das Gefühl haben, dass Ihr Kind überfordert ist</li>
            </ul>
            <p>
                Oder Ihr Kind ist ein „Risikokind“ aufgrund einer Frühgeburt, Geburtsproblemen,
                Mehrlingsgeburt, Beeinträchtigungen, Erkrankungen.
            </p>
            <p>
                Vielleicht wurden Sie aber auch von Ihrer Kinderärztin/ Ihrem Kinderarzt, der Kita, der
                Krankengymnastin/ dem Krankengymnasten, oder der Ergo- oder Sprachtherapeutin/ dem Ergo-
                oder Sprachtherapeut Ihres Kindes, auf die Möglichkeit einer Frühförderung für Ihr Kind
                angesprochen.
            </p>
            <p>
                Dann nehmen Sie Kontakt zu uns auf oder
                Sie wenden sich direkt an den Fachdienst Eingliederungshilfe des Landkreises Schaumburg.
            </p>
            <p>
                Von dort erhalten Sie einen Antrag, den Sie ausgefüllt an den Fachdienst zurückschicken.
            </p>
            <p>
                Gerne unterstützen wir Sie bei der Antragstellung.
            </p>
            <p>
                Die Frühfördermaßnahme ist für Sie kostenlos.
            </p>

        </div>
    },
    {
        title: "Einzelförderung",
        content: <div className="text-secondary">
            <h3 className="mt-2">Einzelförderung </h3>
            <p>
                In der Einzelförderung hat Ihr Kind die Chance eine vertrauensvolle Beziehung zu einem Erwachsenen aufzubauen. Dieser stellt sich auf die Bedürfnisse Ihres Kindes ein.
                Es werden Impulse gesetzt und es wird durch ehrliche Wertschätzung in seiner
                Persönlichkeit gestärkt. Ihr Kind kann sich ausprobieren, Selbstsicherheit gewinnen
                und dadurch bereit sein, sich neuen Herausforderungen zu stellen. Die Individualität
                jedes Einzelnen steht im Vordergrund, Probleme und Schwächen können ohne
                Bedenken gezeigt werden. Ihr Kind wird in seiner ganzheitlichen Entwicklung
                begleitet und gefördert.

            </p>
            <p>
                In den Räumen der Frühförderung hat Ihr Kind die Möglichkeit das umfassende
                Raumangebot zu entdecken und die Förderangebote können noch
                entwicklungsspezifischer und individueller ausgewählt werden. Nach Bedarf und
                nach vorheriger Absprache kann die Förderung auch weiterhin bei Ihnen zu Hause
                oder auch in der Kita Ihres Kindes stattfinden.
            </p>

        </div>
    },
    {
        title: "Gruppenförderung",
        content: <div className="text-secondary">
            <h3 className="mt-2"> Gruppenförderung</h3>
            <p>
                Gruppenförderung ist ein weiteres Angebot für Kinder mit Förderbedarf.
            </p>
            <p>
                Kinder, die zuerst eine Einzelförderung genehmigt bekommen haben, können in eine
                Gruppenförderung wechseln. Ihr Kind kann die in der Einzelförderung erlernten
                Kompetenzen in die Gruppensituation übertragen. Aber auch die direkte Aufnahme in eine
                Gruppenförderung ist möglich.
            </p>

            <p>
                Spiel- und Bewegungsgruppen bieten die Möglichkeit, über das gemeinsame Tun u.a.
                Sozialkompetenzen, körperlichen Ausgleich, Kompensationsstrategien und auch
                emphatisches Miteinander zu erzielen.
            </p>
            <p>
                Zur Vorbereitung auf die Schulzeit Ihres Kindes haben wir die Möglichkeit, in eine
                Lerngruppe zu wechseln, um Sicherheit, Konzentration, Handlungsplanung und
                Impulsregulierung zu erlernen.
            </p>

        </div>
    },
    {
        title: "Unser Team",
        content: <div className="text-secondary">
            <h3 className="mt-2"> Unser Team</h3>
            <p>
                Unser Team besteht aus Fachkräften verschiedener Professionen mit unterschiedlichen
                Schwerpunkten und Zusatzausbildungen.
            </p>
            <ul>
                <li>Staatl. anerkannte/r Heilpädoge*in</li>
                <li>Diplom Sozialpädagoge*in</li>
                <li>M.A. Erziehungswissenschaftler*in</li>
                <li>Marte Meo Practitioner-, Therapeut-, Colleague Trainer*in</li>
                <li>Systemische Familienberater*in</li>
                <li>Entwicklungsbegleiter*in nach Doering</li>
                <li>Staatl. anerkannter Heilerziehungspfleger*in</li>
                <li>Integrative/r Frühpädagoge*in</li>
                <li>Staatl. anerkannte/r Erzieher*in</li>
            </ul>

        </div>
    }
]

class Frühförderung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0">Frühförderung und Entwicklungsberatung</h3>
                        <Carusel bilder={CaruselBilder} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className="container text-center ff-1 mt-3 text-white shadow-sm p-3 bg-info">
                        <h4>
                            „Ein Kind ist wie ein Schmetterling im Wind.
                            Manche fliegen höher als andere,
                            aber alle fliegen so gut sie können.
                            Sie sollten nicht um die Wette fliegen,
                            denn jeder ist anders.
                            Jeder ist speziell und
                            jeder ist wunderschön.“
                        </h4>

                    </div>

                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2">Liebe Eltern, liebe Familien, liebe Interessierte, </h3>
                                    <h5 className="text-success">jedes Kind ist einzigartig, genauso wie seine Entwicklung. </h5>
                                    <p className="text-secondary">
                                        Trotzdem kann eine Förderung für Ihr Kind sinnvoll sein, wenn es zum Beispiel aufgrund
                                        verschiedener Ursachen mehr Unterstützung benötigt als andere Kinder oder Sie sich als
                                        Eltern Sorgen um die Entwicklung Ihres Kindes machen.
                                        Die Frühförderung ist ein ganzheitliches Angebot für Kinder von der Geburt bis zum
                                        Schuleintritt zur Förderung der Entwicklung und als Entwicklungsbegleitung. Dabei können
                                        sowohl alle Entwicklungsbereiche als auch nur einzelne Bereiche, wie zum Beispiel
                                        Kommunikation und Sprache, im Vordergrund stehen. Der Unterstützungsbedarf ist ganz
                                        individuell.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={Bild} width="100%" height="auto" style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                </>


                <>
                    <div className="mt-4 container bg-white shadow-sm rounded p-3 text-secondary">
                        <p>
                            Auf diesen Seiten möchten wir Ihnen unser Angebot näher vorstellen und Ihnen eine
                            erste Orientierung geben. In den Bildern oben sehen Sie bereits erste Einblicke in unsere
                            Arbeit. Es gibt viel zu erleben!
                        </p>
                        <p> Wenn Sie Fragen haben, können Sie selbstverständlich anrufen – wir beraten Sie gern.</p>
                        <p>Ulrike Vetter und das Team der Frühförderung</p>
                    </div>
                </>

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

                <>
                    <div className="container p-0">
                        <div className="row m-0">

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">

                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="Person" /> Ansprechpartner
                                    </div>
                                    <div className="col-12 col-md-6 text-center d-flex ">
                                        <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                            <img src={ABild} width="200px" height="auto" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex text-secondary">
                                        <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                            <h3 className="text-dark">Ulrike Vetter</h3>
                                            <p className="m-0"><Icon icon="Phone" /> 05751 89 104 310</p>
                                            <p className="m-0"><Icon icon="BiMailSend" /> 05751 89 104 319 </p>
                                            <p className="m-0"><Icon icon="Mail" /><small>fruehfoerderung@lebenshilfe-rinteln.de</small></p>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex mb-3 mt-3">
                                        <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                        <Icon icon="GetApp" />  Downloads
                                    </div>

                                    <div className="col-12 row m-0 downloads">
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/früforderung/RezeptSalzteig.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Rezept Salzteig</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/früforderung/RezeptKnete.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Rezept Knete</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>


                                        {/*    <a className="col-12 mt-3 d-flex" href={require('../downloads/früforderung/Medienkonsum.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Medienkonsum</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a> */}
                                        <a className="col-12 mt-3 d-flex" href={require('../downloads/früforderung/Fingerspiele.pdf')} download>
                                            <Icon icon="PictureAsPdf" />  <span className="ml-2">Fingerspiele</span> <span className="ml-auto"><Icon icon="FaCloudDownloadAlt" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            </div>);
    }
}

export default Frühförderung;