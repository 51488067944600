import React from 'react';
import { Link } from "react-router-dom";

import Carusel from '../components/carusel';
import Icon from '../elements/icons';
// import Gallerie from '../components/gallerie';
import ABild from "../css/image/ansprechpartner/benjamin.png";

import Menu from '../components/menu';
class Werkfeuerwehr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }






    render() {
        window.scrollTo(0, 0);

        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0 ff-1">Werkfeuerwehr</h3>
                        <Carusel bilder={require("../css/image/werkfeuerwehr/carusel")()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"container p-0"}>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 bg-white shadow-sm rounded mt-4 pr-2 d-flex">
                                <div className="m-auto">
                                    <h3 className="text-info pt-2 ff-1">Werkfeuerwehr</h3>
                                    {/*   <h5 className="text-success ff-3">„Der Weg ist das Ziel“</h5> */}
                                    <p className="text-secondary">
                                        Die nebenberufliche Werkfeuerwehr wurde am 05.08.1998 von der
                                        Bezirksregierung Hannover anerkannt.
                                        Sie dient in erster Linie für die Sicherstellung des allgemeinen
                                        Brandschutzes der Gebäude auf dem Gelände der Lebenshilfe Rinteln e.V.,
                                        aber auch überörtliche Löschhilfe wird geleistet.
                                        Die Werkfeuerwehr besteht zur Zeit aus ca. 35 Mitgliedern.
                                        Diese kommen aus allen  Arbeitsbereichen der
                                        Lebenshilfe Rinteln e.V. Dieses ist im Schadensfall
                                        ein unwahrscheinlich großer Vorteil, da in jedem Bereich von Anfang an
                                        fachmännische und vor allem ortskundige Hilfe sehr schnell vor Ort ist.
                                    </p>
                                </div>
                            </div>
                            <img className="col-12 col-md-6 mt-4 p-0 pl-2 rounded" src={require('../css/image/werkfeuerwehr/1.jpg')} width="100%" height="100%" style={{ objectFit: "cover" }} alt="" />

                            <div className="col-12  bg-white shadow-sm rounded text-info text-center p-3 mt-3 ff-3">
                                <h4>Engagement, Initiative und Teamfähigkeit</h4>
                            </div>


                            <div className="col-12 bg-white shadow-sm rounded mt-4 text-secondary">
                                <p className="pt-2">
                                    Auch der vorbeugende Brandschutz wird erledigt. Mit allen Mitarbeitern
                                    der Lebenshilfe Rinteln e. V. werden Brandschutzunterweisungen in
                                    regelmäßigen Abständen durchgeführt, auch die Evakuierung der Gebäude
                                    wird geprobt, oftmals auch unangekündigt.
                                    Die Werkfeuerwehr wird, wie jede kommunale Feuerwehr, über digitale
                                    Funkmeldeempfänger (DME) alarmiert. Es sind zurzeit 35 DME bei uns im
                                    Umlauf.
                                    Unsere Werkfeuerwehr-Mitglieder werden auf Landkreisebene und der
                                    NABK-Niedersachsen ausgebildet. Seit 2012 bilden wir in verschiedenen
                                    Bereichen mit eigenen Ausbildern und in Zusammenarbeit des Landkreises
                                    selber aus.

                                    Da viele der hier betreuten Personen, besonders in den Wohnbereichen,
                                    sich im Ernstfall nicht selbständig retten könnten, legen wir ganz
                                    besonders großen Wert auf den vorbeugenden Brandschutz und schnelle
                                    Hilfe. Das Überleben aller unserer Bewohner ist im Schadensfall das
                                    oberste Ziel.
                                    Daher befindet sich seit Frühjahr 2013 unter jeder Matratze eines
                                    bettlägerigen Bewohners ein &quot;Hamburger Rettungstuch&quot;, welches uns eine
                                    schnelle Rettung bzw. Evakuierung des Bewohners ermöglicht. Des
                                    weiteren wurden in den Wohnbereichen alle Bewohnertüren
                                    mit reflektierenden Zahlenkombinationen unter den Türgriffen beschriftet,
                                    damit im Ernstfall (auch im Verrauchten Zustand) von den eingesetzten
                                    Atemschutztrupps eine einfache Orientierung möglich ist und so schnell
                                    das gesuchte Bewohnerzimmer gefunden werden kann.
                                </p>
                            </div>

                        </div>
                    </div>
                </>


                {/*     <>
                    <div className="mt-4 container bg-info shadow-sm rounded p-2 text-white ff-1">
                        <h4>Bildergalerie</h4>
                    </div>
                    <div className="container mt-3">
                        <Gallerie photos={photos} />
                    </div>
                </> */}


                <>

                    <div className="container bg-white shadow-sm rounded mb-3">

                        <div className="row m-0">
                            <div className="col-12 mt-2 bg-info m-0 p-1 text-white">
                                <Icon icon="Person" /> Ansprechpartner
                            </div>

                            <div className="col-12 col-md-6 text-center d-flex ">
                                <div className={this.props.mobileVersion ? "m-auto" : "ml-auto"}>
                                    <img src={ABild} width="200px" height="auto" alt="" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 d-flex text-secondary">
                                <div className={this.props.mobileVersion ? "m-auto" : "mr-auto mt-auto mb-auto"}>
                                    <h3 className="m-0 text-dark"> Benjamin Heim</h3>
                                    <p className="m-0"><Icon icon="Phone" /> 05751- 89 104 901</p>
                                    <p className="m-0"><Icon icon="Mail" /> werkbrandmeister@lebenshilfe-rinteln.de</p>
                                </div>
                            </div>
                            <div className="col-12 d-flex mb-3 mt-3">
                                <Link to="/kontakt" className="m-auto p-3 bg-warning text-white rounded shadow-sm">Zum Kontaktformular</Link>
                            </div>
                        </div>

                    </div>

                </>

            </div>
        </>);
    }
}

export default Werkfeuerwehr;