import React from 'react';
import Menu from '../components/menu';

class TagespflegeAmWaldkater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        window.scrollTo(0, 0);
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>

                <div className='container'>
                    <div className='bg-white rounded shadow-sm text-center text-success p-4 m-5' style={{ height: "20vh" }}>
                        <h3>Tagespflege am Waldkater</h3>
                        <p className='text-secondary'> <b>Ab März 2025:</b> Eine neue Seite erwartet Sie! Bleiben Sie gespannt und verpassen Sie nicht den Start.</p>
                    </div>
                </div>

            </div>
        </>);
    }
}

export default TagespflegeAmWaldkater;